import store from "@/state/store";
import { Role } from "@/state/helpers";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
    meta: {
      authorize: Role.RestaurantOwner,
      authorize_manager: Role.Manager,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "home",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },

  {
    path: "/register",
    name: "register",
    component: () => import("../views/pages/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "home",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot-password",
    component: () => import("../views/pages/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "home",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../views/pages/account/resetPassword"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "home",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },

  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch("auth/logOut");

        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute
            ? {
                name: "home",
              }
            : {
                ...routeFrom,
              }
        );
      },
    },
  },
  // {
  //   path: '/dashboard',
  //   name: 'super_admin_dashboard',
  //   meta: {
  //     authRequired: true,
  //     authorize:Role.SuperAdmin,
  //   },
  //   component: () => import('@/views/pages/super_admin/dashboard/index')
  // },
  // {
  //   path: '/restaurant-listing',
  //   name: 'restaurant_listing',
  //   meta: {
  //     authRequired: true,
  //     authorize:Role.SuperAdmin,
  //   },
  //   component: () => import('@/views/pages/super_admin/restaurant_owner/listing')
  // },
  // {
  //   path: '/create-restaurant',
  //   name: 'create_restaurant',
  //   meta: {
  //     authRequired: true,
  //     authorize:Role.SuperAdmin,
  //   },
  //   component: () => import('@/views/pages/super_admin/restaurant_owner/create')
  // },
  // {
  //   path: '/edit-profile-restaurant/:restaurant_id',
  //   name: 'edit_profile_restaurant',
  //   meta: {
  //     authRequired: true,
  //     authorize:Role.SuperAdmin,
  //   },
  //   component: () => import('@/views/pages/super_admin/restaurant_owner/edit')
  // },

  {
    path: "/",
    name: "home",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/index"),
  },
  {
    path: "/category/listing",
    name: "category",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/dashboard/category/categoryComponent"),
  },
  {
    path: "/csvupload",
    name: "csvupload",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/dashboard/business/csvUpload"),
  },
  {
    path: "/category/add",
    name: "add-category",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/dashboard/category/addCategoryComponent"),
  },
  {
    path: "/update/category/:id",
    name: "update-category",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/category/updateCategory"),
  },
  {
    path: "/business/add",
    name: "business-add",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/business/add"),
  },
  {
    path: "/sub-category/listing",
    name: "sub-category",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/dashboard/subcategory/subcategorylisting"),
  },
  {
    path: "/sub-category/add",
    name: "add-sub-category",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/dashboard/subcategory/addSubCategory"),
  },
  {
    path: "/update/sub-category/:id",
    name: "update-sub-category",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/dashboard/subcategory/updateSubCategory"),
  },
  {
    path: "/location-type/listing",
    name: "location-type",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/dashboard/locationtype/locationTypelisting"),
  },
  {
    path: "/location-type/add",
    name: "add-location-type",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/dashboard/locationtype/addLocationType"),
  },
  {
    path: "/update/location-type/:id",
    name: "update-location-type",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/dashboard/locationtype/updatelocartionType"),
  },
  {
    path: "/users/add",
    name: "add-user",
    meta: {},
    component: () => import("../views/pages/dashboard/user-management/add"),
  },
  {
    path: "/update/users/:id",
    name: "update-user",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/user-management/update"),
  },
  {
    path: "/users/listing",
    name: "user-listing",
    meta: {},
    component: () => import("../views/pages/dashboard/user-management/listing"),
  },
  {
    path: "/freeusers/listing",
    name: "free-user-listing",
    meta: {},
    component: () => import("../views/pages/dashboard/user-management/FreeUserListing"),
  },
  {
    path: "/business/listing",
    name: "business-listing",
    meta: {},
    component: () => import("../views/pages/dashboard/business/listing"),
  },
  {
    path: "/loation-category/add",
    name: "Add Location Category",
    meta: {},
    component: () => import("../views/pages/dashboard/business/add-location-category"),
  },
  {
      path: "/update/location/category/:id",
      name: "update-location-category",
      meta: {
        authRequired: true,
      },
      component: () => import("../views/pages/dashboard/business/updateLocationCategory"),
  },
  {
    path: "/location-category",
    name: "Location Category",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/dashboard/business/location-category"),
  },
  {
    path: "/update/listing/:id",
    name: "update-list",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/business/listingUpdate"),
  },
  {
    path: "/business/listing/claim",
    name: "business-claim",
    meta: {},
    component: () => import("../views/pages/dashboard/business/claim"),
  },
  {
    path: "/features/listing",
    name: "feature-list",
    meta: {},
    component: () => import("../views/pages/dashboard/feature/featureListing"),
  },
  {
    path: "/feature/add",
    name: "feature-add",
    meta: {},
    component: () => import("../views/pages/dashboard/feature/featureAdd"),
  },
  {
    path: "/update/feature/:id",
    name: "update-feature",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/feature/updateFeature"),
  },
  {
    path: "/business/listing/request",
    name: "business-request",
    meta: {},
    component: () => import("../views/pages/dashboard/business/request"),
  },
  {
    path: "/business/listing/approved",
    name: "business-approved",
    meta: {},
    component: () => import("../views/pages/dashboard/business/approved"),
  },
  {
    path: "/business/listing/rejected",
    name: "business-rejected",
    meta: {},
    component: () => import("../views/pages/dashboard/business/rejected"),
  },
  {
    path: "/business/payments",
    name: "payments",
    meta: {},
    component: () => import("../views/pages/dashboard/business/payments"),
  },
  {
    path: "/business/reviews",
    name: "reviews",
    meta: {},
    component: () => import("../views/pages/dashboard/business/reviews"),
  },
  {
    path: "/subscriptions-plan",
    name: "subscriptions",
    meta: {},
    component: () => import("../views/pages/dashboard/plan/listing"),
  },
  {
    path: "/coupon/list",
    name: "coupons",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/coupons/couponList"),
  },
  {
    path: "/coupon/add",
    name: "create-coupons",
    meta:{
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/coupons/couponCreate"),
  },
  {
    path: "/getNotify",
    name: "get-notify",
    meta:{
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/getNotify/getNotify"),
  }

];
