<template>
    <div>
      <label for="goto-page">Go To Page: </label>
      <input 
        id="goto-page" 
        type="number" 
        v-model="pageNumber" 
        class="border ms-2"
      >
      <button type="button" @click="goToPage">Go</button>
    </div>
  </template>

  <script>
export default {
  data() {
    return {
      pageNumber: 1
    };
  },
  methods: {
    goToPage() {
      this.$emit('page-changed', this.pageNumber);
    }
  }
};
</script>

<style scoped>
div {
  margin: 1rem 0;
}
input {
  margin-right: 0.5rem;
  padding: 0.3rem;
  width: 65px;
  border-radius: 50rem;
}
button {
  padding: 0.3rem 0.5rem;
  background-color: #EC421C;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
button:hover {
  background-color: #EC421C;
}
</style>