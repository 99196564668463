<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card border border-radius-12">
         
          <div class="card-body table">
            <div class="row justify-content-center">
              <div class="col-xl-8 col-12">
                <div class="d-flex align-items-sm-center align-items-start gap-lg-3 gap-2 flex-column flex-sm-row"> 
                  <PageHeader :title="title" class="poppins fw-normal ps-3 text-nowrap" />
                
                  <searchInput @getSearched="handleSearch" class="w-100"/>
                  <pageSize @getPageSize="handlePageSize" class="w-100"/>
                
                </div>
             </div>
              
                              <!-- <div :class="{
                    'del_btn-wrapper': true,
                    'border-orange rounded-pill p1': multipuleIds.length >= 1
                  }">

                  <button
                    v-if="multipuleIds.length >= 1"
                    @click="deleteBulk"
                    class="
                      btn
                      border-0
                      px-5
                      fw-bold
                      text-white
                      btn btn-primary
                      waves-effect waves-light
                      w-md
                      primary-button
                      rounded-pill
                    "
                  >
                    Delete All
                  </button>
                  </div> -->
                  <div class="col-xl-4 col-12"> 
                    <div class="d-flex justify-content-end"> 
                    <div class="add_new border-orange rounded-pill p1 fit-content">  <router-link to="/category/add"
                    class="
                      btn
                      btn-primary
                      border-0
                      px-5
                      fw-bold
                      text-white
                      btn btn-primary
                      waves-effect waves-light
                      w-md
                      primary-button
                      rounded-pill
                    "
                  >
                    Add New
                    </router-link></div>
                  </div>
                </div>
         </div>
            
            <!-- end table-responsive -->
            <GeneralTable :isProcessing="processing" 
            :items="categories"  :fields="fields"
            @deleteItem="deletecategory"
            @editItem = "updateCategory"
            :editUrl = "'update-category'"
             >

          </GeneralTable>
          <Pagination
          @paginator="allCategory"
          :pagination="paginatelinks"
          :showing="paginate"
          @page-changed="handlePageChange"
        />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import $ from "jquery";
import GeneralTable from "../../../../components/MagicTable/GeneralTable.vue";
import searchInput from "../../../../components/general/searchInput.vue";
import Pagination from "../../../../components/general/pagination.vue";
import pageSize from "../../../../components/general/pageSize.vue";
export default {
  page: {
    meta: [
      {
        name: "add-user",
        content: appConfig.description,
      },
    ],
  },
  name: "category",
  components: {
    Layout,
    PageHeader,
    GeneralTable,
    pageSize,
    searchInput,
    Pagination
  },
  data() {
    return {
      fields: [
        { key: 'select', label: 'Select', sortable: false, },  
        { key: 'id'},
        { key: 'avatar_with_title', label: 'Category', },
        { key: 'created_at',tdClass:'align-middle'},
        { key: 'updated_at', label: 'Last Updated',tdClass:'align-middle'},
        { key: 'action',tdClass:'align-middle'},
      ],
      currentPageNumber: null,
      multipuleIds: [],
      selectAll: false,
      title: "Poll Category Listing",
      loading: true,
      categories: null,
      processing: false,
      paginate: {},
      paginatelinks: [],
      pageSize: 10, 
    };
  },
  mounted() {
     let url = null;
    if (this.$route.query.currentPage) {      
      url = process.env.VUE_APP_BASEURL + '/categories?page=' + this.$route.query.currentPage;
    }
    this.allCategory({url});
    this.processing = true;
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.items.slice(start, start + this.itemsPerPage);
    }
  },
  methods: {
    handlePageChange(pageNumber) {
      if (pageNumber <= 0) {
          this.triggerSwal("Page not found. Please enter a valid page number.", "error");
        return;
      }

      const lastPageUrl = this.paginate?.last_page;
      let lastPage = 1;
      if (lastPageUrl) {
        const match = lastPageUrl.match(/page=(\d+)/);
        if (match && match[1]) {
          lastPage = parseInt(match[1], 10);
        }
      }

      if (pageNumber > lastPage) {
        this.triggerSwal("Page not found. Please enter a valid page number.", "error");
        return;
      }
      const baseUrl = "categories"; 
      const pageUrl = `${baseUrl}?page=${pageNumber}`;
      this.allCategory({ url: pageUrl });
    },  
    deletecategory(id) {
      let apiUrl = `categories/`;
           let confirmationMessage = "category deleted successfully."
           this.processing = true;
           this.deleteIt(apiUrl, id,confirmationMessage)
           .then(() => {
            this.processing = false;
            this.allCategory({});
            }).catch(error => {
                console.error('Error deleting job:', error);
                this.processing = false;
            });
      console.log('Received ID from child:', id);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString(); 
},
handlePageSize(newPageSize) {
      this.pageSize = newPageSize || 10;
      this.allCategory({ pageSize: this.pageSize });
    },
    randerData(response) {
      this.currentPageNumber = response.data.data.current_page;
      this.paginate = {
      first_page: response?.data?.data?.first_page_url,
      last_page: response?.data?.data?.last_page_url,
              links: response?.data?.data?.links,
              from: response?.data?.data?.from,
              to: response?.data?.data?.to,
              total: response?.data?.data?.total,
     }
     this.paginate.links = this.paginate?.links?.length > 0 ? this.paginate.links:[]
     this.paginatelinks=this.paginate.links.map(item=>({
              url: item.url,
              label: item.label,
              active: item.active,
            }));

      let categories = Array.isArray(response?.data?.data?.data) && response?.data?.data?.data?.length > 0
      ? response?.data?.data?.data
      :[];
      this.categories = categories.map(item=>({
        id: item.id,
        avatar: item.avatar_icon,
        name : item.title,
        created_at: this.formatDate(item.created_at), 
       updated_at: this.formatDate(item.updated_at),

      }));
      console.log(this.categories)
      this.processing = false;

    },

    allCategory({url = null, searchItem = null, pageSize = this.pageSize } = {}) {
      this.processing = true;
      let endPoint = "categories"
      endPoint = url ? url : endPoint;
      this.$axios
        .get(endPoint, {params:{search: searchItem, page_size: pageSize }})
        .then((response) => {
          setInterval(this.randerData(response), 1500);
        })
        .catch((error) => {
          console.log(error.response);
          this.processing = false;
        });
    },
    handleSearch(searchItem) {
       this.allCategory({searchItem});
  },
    updateCategory: function (id) {
      this.$router.push({
        name: "update-category",
        params: { id: id },
        query: { currentPage: this.currentPageNumber },
      });
    },
    async delete(id) {
      try {
        const {
          data: { status },
        } = await this.$axios.delete("categories/" + id);
        this.responseDelete = status;
      } catch (error) {
        console.log(error);
      }
    },
    deleteCategory(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.delete(id);
          let status = this.responseDelete;
          if (status == true) {
            Swal.fire("Deleted!", "Record has been deleted.", "success");
            this.allCategory();
          }
        }
      });
    },
    selectAllRecord() {
      if (this.selectAll) {
        let ids = [];
        this.categories.forEach((element) => {
          ids.push(element.id);
        });
        this.multipuleIds = [...new Set(ids)];
        console.log(this.multipuleIds);
      } else {
        this.multipuleIds = [];

        console.log(this.multipuleIds);
      }
    },
    deleteBulk() {
      this.$axios
        .post("delete-category-bulk", { ids: this.multipuleIds })
        .then((response) => {
          this.allCategory();
          this.triggerSwal(response.data.message, "success");
          this.selectAll = false;
          this.multipuleIds = [];
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>
