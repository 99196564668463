<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card border border-radius-12">
          <div class="card-body table">
            <div class="row">
              <div class="col-xl-8 col-12">

                <div class="d-flex align-items-sm-center align-items-start gap-lg-3 gap-2 flex-column flex-sm-row">
                  <PageHeader title="Reviews Listing" class="poppins fw-normal text-nowrap ps-3" />

                  <searchInput @getSearched="handleSearch" class="w-100" />
                  <pageSize @getPageSize="handlePageSize" class="w-100" />

                </div>
              </div>
              <!-- <div :class="{
               'del_btn-wrapper': true,
                'border-orange rounded-pill p1': multipuleIds.length >= 1
                    }">
                <button
                  v-if="multipuleIds.length >= 1"
                  @click="deleteBulk"
                  class="btn border-0 px-5 fw-bold text-white btn btn-primary waves-effect waves-light w-md primary-button rounded-pill"
                >
                  Delete All
                </button>
              </div> -->
            </div>
          </div>
          <b-modal ref="review-modal" hide-footer isModalVisible title="Review Message" size="lg">
              <template v-slot:default>
                <div v-if="loading">
                  <APILoader :loading="loading" class="align-middle text-center"></APILoader>
                </div>

                <div v-else>
                    {{ reviewByPerson ??  'No review message founds'}}
                </div>
              </template>
            </b-modal>

          <!-- end table-responsive -->
          <GeneralTable :isProcessing="processing" :items="reviews" :fields="fields" :showEyeButton="true" @showItem="showDescription"
            :showActionButton="false"></GeneralTable>
          <Pagination @paginator="allReviews" :pagination="paginatelinks" :showing="paginate"
            @page-changed="handlePageChange" />

        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import Swal from "sweetalert2";
import GeneralTable from "../../../../components/MagicTable/GeneralTable.vue";
import searchInput from "../../../../components/general/searchInput.vue";
import Pagination from "../../../../components/general/pagination.vue";
import pageSize from "../../../../components/general/pageSize.vue";

export default {
  name: "sub-category",
  components: {
    Layout,
    PageHeader,
    GeneralTable,
    searchInput,
    pageSize,
    Pagination,
  },
  data() {
    return {
      currentPageNumber: null,
      fields: [
        { key: 'select', label: 'Select', sortable: false, },
        { key: 'id' },
        { key: 'commentable_name', label: 'Restaurant Name' },
        { key: 'avatar_with_title', label: 'User Name'},
        { key: 'message', label: 'Message' },
        { key: 'star', label: 'Rating' },
        { key: 'created_at', tdClass: 'align-middle' },
        { key: 'action', tdClass: 'align-middle' },
      ],
      multipuleIds: [],
      reviewByPerson: null,
      selectAll: false,
      title: "Reviews",
      loading: true,
      reviews: [],
      processing: false,
      paginate: {},
      paginatelinks: [],
      pageSize: 10,

    };
  },
  mounted() {
    let url = null;
    if (this.$route.query.currentPage) {
      url = process.env.VUE_APP_BASEURL + '/reviews?page=' + this.$route.query.currentPage;
    }
    this.allReviews({ url });
    this.processing = true;
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.items.slice(start, start + this.itemsPerPage);
    }
  },
  methods: {
    showDescription(review) {
      this.fetchDescription(review);
    },
    fetchDescription(id) {
      this.loading = true;
      this.$axios
        .get(`reviews/${id}`)
        .then(response => {
          this.reviewByPerson = response.data?.data?.message;
          this.loading = false;
          this.openPopup();
        })
        .catch(error => {
          console.error('Error fetching invoices:', error);
          this.loading = false; 
        });
    },
    openPopup() {
        this.$refs['review-modal'].show();
      },
      hideModal() {
          return this.$refs['review-modal'].hide();
      },
    handlePageChange(pageNumber) {
      if (pageNumber <= 0) {
        this.triggerSwal("Page not found. Please enter a valid page number.", "error");
        return;
      }

      const lastPageUrl = this.paginate?.last_page;
      let lastPage = 1;
      if (lastPageUrl) {
        const match = lastPageUrl.match(/page=(\d+)/);
        if (match && match[1]) {
          lastPage = parseInt(match[1], 10);
        }
      }

      if (pageNumber > lastPage) {
        this.triggerSwal("Page not found. Please enter a valid page number.", "error");
        return;
      }
      const baseUrl = "reviews";
      const pageUrl = `${baseUrl}?page=${pageNumber}`;
      this.allReviews({ url: pageUrl });
    },

    handlePageSize(newPageSize) {
      this.pageSize = newPageSize || 10;
      this.allReviews({ pageSize: this.pageSize });
    },
    randerData(response) {
      this.currentPageNumber = response.data.data.current_page;
      this.paginate = {
        first_page: response?.data?.data?.first_page_url,
        last_page: response?.data?.data?.last_page_url,
        links: response?.data?.data?.links,
        from: response?.data?.data?.from,
        to: response?.data?.data?.to,
        total: response?.data?.data?.total,
      }
      this.paginate.links = this.paginate?.links?.length > 0 ? this.paginate.links : []
      this.paginatelinks = this.paginate.links.map(item => ({
        url: item.url,
        label: item.label,
        active: item.active,
      }));

      let allReviews = Array.isArray(response?.data?.data?.data) && response?.data?.data?.data?.length > 0
        ? response.data.data.data
        : [];
      console.log(allReviews, "allllllll");
      this.reviews = allReviews.map(review => ({
      id: review.id,
      avatar: review?.user?.avatar,
      star: review.star,
      message: this.truncateMessage(review?.message),
      created_at: this.formatDate(review.created_at),
      name: review?.user?.full_name,
      commentable_name: review?.commentable?.title || "N/A",
    }));
console.log(this.reviews, "formatted locationTypes");

      this.processing = false;
    },
    allReviews({ url = null, searchItem = null, pageSize = this.pageSize } = {}) {
      let endPoint = "reviews";
      endPoint = url ? url : endPoint;
      this.$axios
        .get(endPoint, { params: { search: searchItem, page_size: pageSize } })
        .then((response) => {
          console.log(response.data.data, "then");
          setInterval(this.randerData(response), 1500);
        })
        .catch((error) => {
          if (error.response == undefined) {
            this.reviews = [];
          }
          console.log(error.response, "error");
          this.processing = false;
        });
    },
    truncateMessage(message, maxLength = 50) {
      if (message?.length > maxLength) {
        return message.substring(0, maxLength) + "...";
      }
      return message ?? 'N/A';
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString(); 
},
    // async delete(id) {
    //   try {
    //     const {
    //       data: { status },
    //     } = await this.$axios.delete("location-type/" + id);
    //     this.responseDelete = status;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    // deleteLocationType(id) {
    //   Swal.fire({
    //     title: "Are you sure?",
    //     text: "You won't be able to revert this!",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Yes, delete it!",
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       await this.delete(id);
    //       let status = this.responseDelete;
    //       if (status == true) {
    //         Swal.fire("Deleted!", "Record has been deleted.", "success");
    //         this.allLocationType({});
    //       }
    //     }
    //   });
    // },
    // locationTypeUpdate: function (id) {
    //   this.$router.push({
    //     name: "update-location-type",
    //     params: { id: id },
    //     query: { currentPage: this.currentPageNumber },
    //   });
    // },
    handleSearch(searchItem) {
      this.allReviews({ searchItem });
    },

    // selectAllRecord() {
    //   if (this.selectAll) {
    //     let ids = [];
    //     this.locationTypes.forEach((element) => {
    //       ids.push(element.id);
    //     });
    //     this.multipuleIds = [...new Set(ids)];
    //     console.log(this.multipuleIds);
    //   } else {
    //     this.multipuleIds = [];

    //     console.log(this.multipuleIds);
    //   }
    // },
    // deleteBulk() {
    //   this.$axios
    //     .post("location-type/bulk/delete/records", { ids: this.multipuleIds })
    //     .then((response) => {
    //       this.allLocationType({});
    //       this.triggerSwal(response.data.message, "success");
    //       this.selectAll = false;
    //       this.multipuleIds = [];
    //     })
    //     .catch((error) => {
    //       console.log(error.response);
    //     });
    // },
  },
};
</script>
