<script>
import countTo from "vue-count-to";
import customerChart from "./customerChart.vue"
import resturantChart from "./resturantChart.vue";
import activeProfileChart from './activeProfileChart.vue';
import paymentChart from './paymentChart.vue'
import MonthYearPicker from "./MonthYearPicker.vue"
import { watch } from "vue";
/**
 * Stat component
 */
export default {
  components: {
    countTo,
    customerChart,
    resturantChart,
    activeProfileChart,
    paymentChart,
    MonthYearPicker,
  },
  props: ["stats", "totalClaims", "acceptedCount", "rejectedClaimsCount", "sponsoredBusines", "payments"],
  data() {
    return {
      month_number: new Date(Date.now()).toLocaleString("en-US", {
            month: "long",
         }),
        //  year: new Date().getFullYear().toString(),
  }

},
watch: {
  month_number: {
    immediate: true,
    handler(newMonth, oldMonth) {
      if (newMonth !== oldMonth) {
        const [month, year] = newMonth.split(" ");
        this.$emit('toggle-month', { month: month, year: year });
      }
    },
  },
},
 



}
</script>

<template>
  <div class="row g-3 mb-3">
    <div class=" col-xl-6">
     <div class="card h-100">
    <div class="card-body border border-radius-12">
      <div class = "d-flex justify-content-between">
      <div> 
        <p class="mb-0 fs-18">Customers</p>
        <p class="mb-0 text-muted">Registered this week</p>
        <p class="mb-0">{{stats.users.Total_Week_Users}}+</p>
      </div>
      <div>
        <p class="mb-0 gray-text">Total Customers</p>
        <h4 class="mb-1 mt-1 header-title text-end">
          <span data-plugin="counterup">
            <countTo :startVal="1000" :endVal="Number(stats.users.Total_Users)" :duration="2000"></countTo>
          </span>
        </h4>
      
      </div>
    </div>
    <customerChart :newStats="stats" />
    </div>
   </div>
    </div>
    <div class=" col-xl-6">
      <div class="card h-100">
        <div class="card-body border-radius-12 border">
          <div class = "d-flex justify-content-between">
      <div> 
        <p class="mb-0 fs-18">Restaurants</p>
        <p class="mb-0 text-muted">Registered this month</p>
        <p class="mb-0">{{stats.businesses.Restaurants_This_Month}}+</p>
      </div>
          <div>
            <p class="gray-text mb-0">Total Restaurants</p>
            <h4 class="mb-1 mt-1 header-title text-end">
              <span data-plugin="counterup">
                <countTo :startVal="1000" :endVal="Number(stats.businesses.Total_Restaurants)" :duration="2000"></countTo>
              </span>
            </h4>
           
          </div>
          </div>
               <resturantChart :newStats="stats"/>
        </div>
      </div>
    </div>
    <div class=" col-xl-7 col-xxl-8">
      <div class="card border border-radius-12 h-100">
        <div class="card-body">
          <div>
            <!-- <h4 class="mb-1 mt-1 header-title">
              <span data-plugin="counterup">
                <countTo :startVal="1000" :endVal="Number(stats.subscriptions)" :duration="2000"></countTo>
              </span>
            </h4> -->
            <p class="gray-text fs-18 mb-0">Active Profile</p>
          </div>
          <activeProfileChart :newStats="stats" />
        </div>
      </div>
    </div>
    <div class=" col-xl-5 col-xxl-4">
      <div class="card border border-radius-12 h-100 ">
        <div class="card-body text-center text-md-start mt-3">
          <div>
            <div class="d-flex justify-content-between">
             <p class="gray-text mb-0 fs-18">Payments</p>
              <MonthYearPicker class="w-50" v-model="month_number"></MonthYearPicker>
             </div>
            
          </div>
          <paymentChart :newStats="stats"/>
          <div>
            <p class="font-weight-bold">Total Payment {{ stats.totalAmountCount }}$</p> 
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
