<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal" />
    <div class="addUser">
  <div class="row">
    <div class="col-lg-12 col-12">
      <div class="card">
        <div class="card-body">
          <div> 
            <p class="gray-text f-18 fw-bold">Profile Picture</p>
            <section :style="{
        'background-image': 'url(' + temp_avatar + ')',
        'background-repeat': 'no-repeat',
        'background-size': '100%',
        'background-position': 'center',
      }" class="
          free_listing-banner
          d-flex
          align-items-center
          justify-content-center
          
          position-relative
        ">
        <div class="container">
          <div class="banner_content-wrapper">
            <div class="banner_image__picker text-center pointer">
              <input type="file" name="file-input" id="file-input" class="
                  file-input__input
                  position-absolute
                  overflow-hidden
                  invisible
                " @change="onFilebanner" ref="banner" />
              <label class="
                  file-input__label
                  d-inline-flex
                  align-items-center
                  text-white
                  pointer
                  rounded
                  grey-bg
                  py-2
                  px-3
                  f-18
                  text-dark
                " for="file-input">
                <template v-if="!form.avatar" >
                  <img src="@/assets/images/AddUser.svg" alt="Add User">
                <span class="f-16 fw-400  mb-0">Upload  <br> Logo</span>
               </template>
              </label>
            </div>
          </div>
        </div>
      </section>           
          </div>
          <div> 
            <p class="text-start f-18 fw-bold gray-text py-3 mb-0"> User info</p>
          </div>
          <form ref="form" @submit.prevent="submit">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group mt-4 mt-md-0 mb-0 mb-mb-3">
          <BreezeInput
            id="floatingInputName"
            type="text"
            class="form-control input-padding fs-14 rounded-pill"
            placeholder=""
            aria-describedby="nameHelpInline"
            required
            v-model="form.first_name"
          />
          <BreezeLabel
            for="floatingInputName"
            class="gray-text fs-14 px-2"
            value="Enter Your First Name"
          />
          <div v-if="$v.form.first_name.$error">
            <div class="error" v-if="!$v.form.first_name.required">
              First Name is Required!
            </div>
            <!-- Uncomment the alpha check if needed -->
            <!-- <div class="error" v-if="!$v.form.first_name.alpha">
              Please Enter Letters only
            </div> -->
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group mt-4 mt-md-0 mb-0 mb-3">
          <BreezeInput
            id="floatingInputLastName"
            type="text"
            class="form-control input-padding fs-14 rounded-pill"
            placeholder=""
            aria-describedby="nameHelpInline"
            required
            v-model="form.last_name"
          />
          <BreezeLabel
            for="floatingInputLastName"
            class="gray-text fs-14 px-2"
            value="Enter Your Last Name"
          />
          <div v-if="$v.form.last_name.$error">
            <div class="error" v-if="!$v.form.last_name.required">
              Last Name is Required!
            </div>
            <!-- Uncomment the alpha check if needed -->
            <!-- <div class="error" v-if="!$v.form.last_name.alpha">
              Please Enter Letters only
            </div> -->
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-3 form-group">
          <BreezeInput
            id="formrow-email-input"
            type="email"
            class="form-control fs-14 rounded-pill px-4 input-padding"
            required
            v-model="form.email"
            placeholder=""
            autocomplete="email"
          />
          <BreezeLabel
            for="formrow-email-input"
            class="gray-text fs-14 px-2"
            value="Email"
          />
          <div class="error" v-if="$v.form.email.$error">
            <div class="error" v-if="!$v.form.email.required">
              Email is Required!
            </div>
            <div class="error" v-if="!$v.form.email.email">
              Please Enter Valid Email
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3 form-group">
          <BreezeInput
            id="formrow-mobile-input"
            type="number"
            class="form-control fs-14 rounded-pill px-4 input-padding"
            required
            v-model="form.mobile"
            placeholder=""
            autocomplete="number"
          />
          <BreezeLabel
            for="formrow-mobile-input"
            class="gray-text fs-14 px-2"
            value="Phone"
          />
          <div class="error" v-if="$v.form.mobile.$error">
            <div class="error" v-if="!$v.form.mobile.required">
              Mobile Number is Required!
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3 form-group">
          <BreezeInput
            id="address-field-city"
            type="text"
            class="form-control fs-14 rounded-pill px-4 input-padding"
            required
            v-model="address.city"
            placeholder=""
            autocomplete="number"
          />
          <BreezeLabel
            for="address-field-city"
            class="gray-text fs-14 px-2"
            value="City/Town"
          />
          <div class="error" v-if="$v.address.city.$error">
            <div class="error" v-if="!$v.address.city.required">
              City/Town is Required.
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4"> 
        <div class="mb-3 form-group">
          <select class="form-select border-radius-24 fs-14 mb-4 gray-text" 
            aria-label="T Shirt Size" 
            required 
            v-model="address.country">
            <option value="" disabled>Country*</option>
            <option
                v-for="(name, code) in countries"
                :key="code"
                :value="code"
            >
                {{ name }}
            </option>
          </select>
          <div class="error" v-if="$v.address.country.$error">
            <div class="error" v-if="!$v.address.country.required">
             Country is Required.
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3 form-group">
          <BreezeInput
            id="state-id"
            type="text"
            class="form-control fs-14 rounded-pill px-4 input-padding"
            required
            v-model="address.state"
            placeholder=""
            autocomplete="number"
          />
          <BreezeLabel
            for="state-id"
            class="gray-text fs-14 px-2"
            value=" State/Province"
          />
          <div class="error" v-if="$v.address.state.$error">
            <div class="error" v-if="!$v.address.state.required">
             State/Province is Required.
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3 form-group">
          <BreezeInput
            id="zip_id"
            type="text"
            class="form-control fs-14 rounded-pill px-4 input-padding"
            required
            v-model="address.zipcode"
            placeholder=""
            autocomplete="number"
          />
          <BreezeLabel
            for="zip_id"
            class="gray-text fs-14 px-2"
            value=" Zip Code"
          />
          <div class="error" v-if="$v.address.zipcode.$error">
            <div class="error" v-if="!$v.address.zipcode.required">
            Zip Code is Required.
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-3 form-group">
          <BreezeInput
            type="text"
            class="form-control fs-14 rounded-pill px-4 input-padding"
            v-model="address.address"
            id="formrow-Contact-input"
            placeholder=""
          />
          <BreezeLabel
            class="gray-text fs-14 px-2"
            for="formrow-Contact-input"
          >
            Address
          </BreezeLabel>
          <div class="error" v-if="$v.address.address.$error">
            <div class="error" v-if="!$v.address.address.required">
              Address is Required!
            </div>
          </div>
        </div>
      </div>
      <template v-if="!isFree">
      <div class="col-md-4"> 
        <div class="mb-3 form-group">
          <select class="form-select border-radius-24 fs-14 mb-4 gray-text" 
            aria-label="T Shirt Size" 
            required 
            v-model="form.t_shirt_size">
            <option value="">T Shirt Size</option>
            <option value="1">XS</option>
            <option value="2">S</option>
            <option value="3">M</option>
            <option value="4">L</option>
            <option value="5">XL</option>
            <option value="6">XXL</option>
            <option value="6">XXXL</option>
          </select>
          <!-- <div class="error" v-if="$v.form.t_shirt_size.$error">
            <div class="error" v-if="!$v.form.t_shirt_size.required">
              T-shirt Size is required!
            </div>
          </div> -->
        </div>
      </div>
      </template>
      <div class="col-md-4">
        <div class="mb-3 form-group">
          <BreezeInput
            type="password"
            :class="['form-control input-padding fs-14 light-bg rounded-pill', isPasswordRequired && !isPasswordStrong ? 'border-danger' : isPasswordStrong ? 'border-success' : '']"
            v-model="form.password"
            id="formrow-Password"
            placeholder=""
          />
          <BreezeLabel
            class="form-label gray-text fs-14 px-2"
            for="formrow-Password"
          >
            Password
          </BreezeLabel>
          <div class="error" v-if="$v.form.password.$error">
            <p class="text-danger"  v-if="!$v.form.password.required"
                   >Password is required</p>
            <p v-if="!isPasswordStrong && form.password?.length > 0" class="text-danger">
                                                Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character.
                                                </p>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-3 form-group">
          <BreezeInput
            type="password"
            class="form-control fs-14 rounded-pill px-4 input-padding"
            v-model="form.confirmPassword"
            id="c-password"
            placeholder=""
          />
          <BreezeLabel
            class="form-label gray-text fs-14 px-2"
            for="c-password"
          >
            Confirm Password
          </BreezeLabel>
          <div class="error" v-if="$v.form.confirmPassword.$error">
            <div class="error" v-if="!$v.form.confirmPassword.required">
              Confirm Password is Required!
            </div>
            <div class="error " v-if="!$v.form.confirmPassword.sameAsPassword">
              Password and Confirm Password Should Match
            </div>
          </div>
        </div>
      </div>
      <template v-if="!isFree">
      <div class="col-md-4">
        <div class="mb-3 d-flex align-items-center">
          <input
            type="checkbox"
            class=""
            v-model="form.one_year_free"
            true-value="1"
            false-value="0"
            @change="toggleCheckbox('one_year_free')"
            id="oneYear"
            placeholder=""
          />
          <label
            class="ms-2 mb-0"
            for="oneYear"
          >
            One year Free
          </label>
        
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3 d-flex align-items-center">
          <input
            type="checkbox"
            class=""
            v-model="form.life_time_free"
            true-value="1"
            false-value="0"
            @change="toggleCheckbox('life_time_free')"
            id="lifeTime"
          />
          <label
            class="ms-2 mb-0"
            for="lifeTime"
          >
            Life Time Free
          </label>
       
        </div>
      </div>
</template>
    
    </div>

    <div class="d-flex justify-content-end gap-3"> 
      <div class="cta_submit button text-end border-orange fit-content rounded-pill p1 float-end">
        <SubmitButton
          :processing="processing"
          type="submit"
          class="btn btn-primary waves-effect waves-light w-md"
          text="Add User"
        />
      </div>
       <!-- Uncomment if you want a cancel button 
      <div class="cta_submit button text-end border-dark fit-content rounded-pill p1 float-end"> 
        <SubmitButton
           :processing="processing"
          type="reset"
          :color='"btn waves-effect waves-light w-md bg-black py-2"' 
          text="Cancel"
        /> 
      </div> -->
    </div>
  </form>
  </div>
        </div>
      </div>
    </div>
  </div>


  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import { required, email, alpha,sameAs, minLength } from "vuelidate/lib/validators";
import BreezeInput from "@/components/Input/input";
import BreezeLabel from "@/components/Input/label";

export default {
 
  page: {
    title: "Add User",
    meta: [
      {
        name: "add-user",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    BreezeInput,
    BreezeLabel
  },
  data() {
    return {
      countries: [],
      processing: false,
      temp_avatar:null,
      isFree: false,
      form: {
        avatar: null,
        // banner: "",
        first_name: null,
        last_name: null,
        email: null,
        mobile: null,
        password:null,
        confirmPassword:null,
        t_shirt_size:"",
        one_year_free: 0,
        life_time_free: 0,
      },
      address:{
        city:"",
        country:"",
        state:"",
        zipcode:"",
        },
      title: "Add User",
      items: [
        {
          text: "Omaha",
        },
        {
          text: "Add User",
          active: true,
        },
      ],
    };
  },
  validations: {
    form: {
      first_name: {
        required,
        // alpha,
      },
      last_name: {
        required,
        // alpha,
      },
      email: {
        email,
        required,
      },
      mobile: {
        required,
      },
      password:{
        required,
        minLength: minLength(8)
      },
      confirmPassword:{
        required,
        sameAsPassword: sameAs('password')
      },
      // t_shirt_size:{
      //   required,
      // },
    },
      address:{
        address:{
        required
      },
        city:{
        required,
      },
      country:{
        required,
      },
      state:{
        required,
      },
      zipcode:{
        required,
      }

      }    
    },
    computed: {
        isPasswordStrong() {
            const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            return regex.test(this.form.password);
        }


    },
    mounted() {
      if (this.$route.query.free === 'true') {
          this.isFree = true;
      }
      this.getCountries();
    },
  methods: {
    toggleCheckbox(selected) {
      if (selected === "one_year_free") {
        this.form.life_time_free = 0; 
      } else if (selected === "life_time_free") {
        this.form.one_year_free = 0; 
      }
    },
    onFilebanner(e) {
      const file = e.target.files[0];
      this.form.avatar = this.$refs.banner.files[0];
      this.temp_avatar = URL.createObjectURL(file);
    },
    selectMedia(e) {
      const file = e.target.files[0];
      console.log(file);
    },
    getCountries() {
         this.$axios
          .get("countries")
          .then((response) => {
              console.log(response.data);
              this.countries = response?.data?.data;
              console.log(this.countries,'countries');

          })
          .catch((error) => {
              console.error('Error fetching countries:', error);
          });
    },
    submit() {
      
      this.$v.$touch();
      if (this.$v.$invalid || !this.isPasswordStrong) {
        this.submitStatus = "ERROR";
      } else {
        let self = this;
        self.processing = true;
        console.log(this.address);
        const formData = new FormData();
        if (this.form.avatar) {
            formData.append("avatar", this.form.avatar);
          }
          if(this.isFree){
            formData.append("is_free_member", 1);
          }
        formData.append("first_name", this.form.first_name);
        formData.append("last_name", this.form.last_name);
        formData.append("email", this.form.email);
        formData.append("mobile", this.form.mobile);
        formData.append("password", this.form.password);
        formData.append("confirmPassword", this.form.confirmPassword);
        formData.append("t_shirt_size", this.form.t_shirt_size);
        formData.append('address',JSON.stringify(this.address));
        formData.append('life_time_free',this.form.life_time_free);
        formData.append('one_year_free',this.form.one_year_free);
        this.$axios
          .post("create-user",formData)
          .then((response) => {
            if(this.isFree){
              this.$router.push({ path: "/FreeUsers/Listing" });
              this.isFree = false;
            } else {
              this.$router.push({ path: "/users/listing" });
            }
            // this.$router.push({ path: "/users/listing" });
            this.triggerSwal(response.data.message, "success");
            this.$refs.form.reset();
            this.processing = false;
          })
          .catch((error) => {
            this.handleErrorResponse(error.response, "error");
            this.processing = false;
          });
      }
    },
  },
 
};
</script>
<style scoped>
/***Form Fields****/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>