<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card border border-radius-12">

          <div class="card-body table mb-0">
            <div class="row justify-content-between">

              <div class="col-xl-8 col-12">
                <div class="d-flex align-items-sm-center align-items-start gap-lg-3 gap-2 flex-column flex-sm-row">
                  <PageHeader :title="title" :items="items" class="poppins fw-normal ps-3 text-nowrap" />
                  <searchInput @getSearched="handleSearch" class="w-50" />
                  <!-- <pageSize @getPageSize="handlePageSize" class="w-100" /> -->
                </div>

              </div>
            </div>
            <GeneralTable :isProcessing="processing" :items="items" :fields="fields" @no-records-found="onRecordsFount" :showEyeButton="true" :showActionButton="false"
              @showItem="showInovice">
            </GeneralTable>
            <b-modal ref="invoice-modal" hide-footer isModalVisible title="Invoice Details" size="lg">
              <template v-slot:default>
                <div v-if="loading">
                  <APILoader :loading="loading" class="align-middle text-center"></APILoader>
                </div>

                <div v-else>
                  <div class="invoice-info">
                    <div class="invoice-column">
                      <p><strong>Customer Name:</strong> {{ invoiceData?.customer_name ?? 'N/A' }}</p>
                      <p><strong>Customer Email:</strong> {{ invoiceData?.customer_email ?? 'N/A' }}</p>
                      <p><strong>Amount Due:</strong> {{ formatAmount(invoiceData?.amount_due) ?? 'N/A' }}</p>
                      <p><strong>Amount Paid:</strong> {{ formatAmount(invoiceData?.amount_paid) ?? 'N/A'}}</p>
                      <p><strong>Amount Remaining:</strong> {{ formatAmount(invoiceData?.amount_remaining) ?? 'N/A' }}</p>
                      <p><strong>End Date:</strong> {{ subscriptionEndDate  }}</p>
                      <p><strong>Discount:</strong> {{ discountType }}</p>
                    </div>
                    <div class="invoice-column">
                      <p><strong>Status:</strong> {{ invoiceData?.status ?? 'N/A' }}</p>
                      <h6 class="mt-4">Items:</h6>
                      <div v-for="lineItem in invoiceData?.lines?.data" :key="lineItem.id" class="mb-3">
                        <p><strong>Description:</strong> {{ lineItem?.description }}</p>
                        <p><strong>Amount:</strong> {{ formatAmount(lineItem?.amount) }}</p>
                        <p><strong>Quantity:</strong> {{ lineItem?.quantity }}</p>
                      </div>
                      <p>
                        <strong>Invoice PDF:</strong>
                        <a :href="invoiceData?.invoice_pdf" target="_blank">View PDF</a>
                      </p>
                      <p>
                        <strong>Hosted Invoice URL:</strong>
                        <a :href="invoiceData?.hosted_invoice_url" target="_blank">View Invoice</a>
                      </p>
                    </div>
                  </div>
                </div>
              </template>
            </b-modal>
            <Pagination @paginator="userPayment" :pagination="paginatelinks" :showing="paginate"
              @page-changed="handlePageChange" />
            <!-- end table-responsive -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import $ from "jquery";
import GeneralTable from "../../../../components/MagicTable/GeneralTable.vue";
import Pagination from "../../../../components/general/pagination.vue";
import searchInput from "../../../../components/general/searchInput.vue";
// import pageSize from "../../../../components/general/pageSize.vue";

export default {
  page: {
    title: "Subscription Listing",
    meta: [
      {
        name: "users-payment",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    GeneralTable,
    Pagination,
    searchInput,
  },

  data() {
    return {
      currentPageNumber: null,
      fields: [
        { key: 'id' },
        {key:'name', label: 'Name', tdClass: 'align-middle'},
        { key: 'email', tdClass: 'align-middle' },
        { key: 'mobile', label: 'Phone', tdClass: 'align-middle' },
        { key: 'subscription_name', label: 'Subscription Name' }, 
        { key: 'stripe_status', label: 'Stripe Status', tdClass: 'align-middle' }, 
        { key: 'discount', label: 'Discount Status', tdClass: 'align-middle' }, 
        { key: 'action', tdClass: '' },

      ],
      sort_by: '',
      selectedRows: [],
      items: [],
      currentPage: 1,
      itemsPerPage: 10,
      multipuleIds: [],
      selectAll: false,
      loading: true,
      users: null,
      paginatelinks: [],
      title: "Subscription Listing",
      processing: false,
      paginate: {},
      pageSize: 10,
      invoiceData: null,
      subscriptionEndDate: null,
      discountType: null,

    };
  },

  mounted() {
    let url = null;
    if (this.$route.query.currentPage) {
      url = process.env.VUE_APP_BASEURL + '/subscription-payments-?page=' + this.$route.query.currentPage;
    }
    this.userPayment({ url });
    this.processing = true;
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.items.slice(start, start + this.itemsPerPage);
    }
  },
  methods: {
    formatAmount(amount) {
      return `$${(amount / 100).toFixed(2)}`;
    },
    showInovice(userId) {
      this.loading = true;
      this.invoiceData = null; 
      this.fetchInvoice(userId);
    },
    fetchInvoice(userId) {
      this.$axios
        .get(`invoices`, { params: { user_id: userId } })
        .then(response => {
          this.openPopup();
          this.loading = false;
          console.log(response.data.subscription.created_at);
          let createdAt = new Date(response.data.subscription.created_at);
          createdAt.setFullYear(createdAt.getFullYear() + 1);
          this.subscriptionEndDate = this.formatDate(createdAt.toISOString());
          this.invoiceData = response.data.invoices;
          this.discountType = response.data.discountType;
        })
        .catch(error => {
          console.error('Error fetching invoices:', error);
          this.loading = false; 
        });
    },

    openPopup() {
        this.$refs['invoice-modal'].show();
      },
      hideModal() {
          return this.$refs['invoice-modal'].hide();
      },
  
    onRecordsFount() {
      this.userPayment({ pageSize: 10, searchItem: null, sortBy: null });
      this.currentPage = 1;
    },
    handlePageChange(pageNumber) {
      if (pageNumber <= 0) {
        this.triggerSwal("Page not found. Please enter a valid page number.", "error");
        return;
      }

      const lastPageUrl = this.paginate?.last_page;
      let lastPage = 1;
      if (lastPageUrl) {
        const match = lastPageUrl.match(/page=(\d+)/);
        if (match && match[1]) {
          lastPage = parseInt(match[1], 10);
        }
      }

      if (pageNumber > lastPage) {
        this.triggerSwal("Page not found. Please enter a valid page number.", "error");
        return;
      }
      const baseUrl = "subscription-payments";
      const pageUrl = `${baseUrl}?page=${pageNumber}`;
      this.userPayment({ url: pageUrl });
    },
    deleteUser(id) {
      let apiUrl = `delete-user/`;
      let confirmationMessage = "User deleted successfully."
      this.processing = true;
      this.deleteIt(apiUrl, id, confirmationMessage)
        .then(() => {
          this.processing = false;
          this.userPayment({});
        }).catch(error => {
          console.error('Error deleting job:', error);
          this.processing = false;
        });
    },
    handleSearch(searchItem) {
      this.userPayment({ searchItem });
      // this.userPayment({ url: `search-users?query=${searchTerm}` }); 
    },
    filtration() {
      this.userPayment({ sortBy: this.sort_by });
    },
    updateCurrentPage(newPage) {
      this.currentPage = newPage;
    },
    handlePageSize(newPageSize) {
      this.pageSize = newPageSize || 10;
      this.userPayment({ pageSize: this.pageSize });
    },
    randerData(response) {
      const paymentData = response?.data?.data;

      this.currentPageNumber = paymentData.current_page;
      this.paginate = {
        first_page: paymentData.first_page_url,
        last_page: paymentData.last_page_url,
        links: paymentData.links,
        from: paymentData.from,
        to: paymentData.to,
        total: paymentData.total,
      };
      this.paginatelinks = this.paginate.links.map((item) => ({
        url: item.url,
        label: item.label,
        active: item.active,
      }));

      let users = Array.isArray(paymentData?.data) && paymentData?.data.length > 0
        ? paymentData?.data
        : [];
      this.items = users.map((user) => {
        const subscriptionName = user.subscriptions.length > 0
  ? user.subscriptions
      .map(subscription => 
        user.amount_paid === 0 
          ? 'Free-Subscription' 
          : 'Yearly-Subscription'
      )
      .join(', ')
  : 'No Subscription';

        const stripeStatus = user.subscriptions.length > 0
          ? user.subscriptions.map(subscription => subscription.stripe_status).join(', ')
          : 'No Subscription';

        return {
          id: user.id,
          avatar: user.avatar,
          name: user.full_name,
          email: user.email,
          mobile: user.mobile,
          t_shirt_size: user.t_shirt_size,
          registeredon: user.created_at_formatted,
          subscription_name: subscriptionName,
          stripe_status: stripeStatus, 
          discount: user.discount
        };
      });

      this.processing = false;
    },

      userPayment({ url = null , searchItem=null } = {}) {
      this.processing = true;
      let endPoint = "subscription-payments";
      endPoint = url ? url : endPoint;
      this.$axios
        .get(endPoint, {
          params: {
            search: searchItem,
          }
        })
        .then((response) => {
          console.log("i have response",response);
          setTimeout(() => this.randerData(response), 1500);
        })
        .catch((error) => {
          console.log(error.response);
          this.processing = false;
        });
    },

    updateRecords(records) {
      this.users = records;
      this.links = records;
    },
    // Record update Function
    userUpdate: function (id) {
      this.$router.push({
        name: "update-user",
        params: { id: id },
        query: { currentPage: this.currentPageNumber, free: true },
      });
    },
    selectAllRecord() {
      if (this.selectAll) {
        let ids = [];
        this.users.forEach((element) => {
          ids.push(element.id);
        });
        this.multipuleIds = [...new Set(ids)];
        console.log(this.multipuleIds);
      } else {
        this.multipuleIds = [];

        console.log(this.multipuleIds);
      }
    },
    deleteBulk() {
      this.$axios
        .post("delete-user-bulk", { ids: this.multipuleIds })
        .then((response) => {
          this.userPayment({});
          this.triggerSwal(response.data.message, "success");
          this.selectAll = false;
          this.multipuleIds = [];
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    formatDate(isoDate) {
    let date = new Date(isoDate);

    let day = date.getDate();
    let month = date.toLocaleString('default', { month: 'long' }); 
    let year = date.getFullYear();

    let getDaySuffix = (day) => {
        if (day > 3 && day < 21) return "th";
        switch (day % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    };

    return `${day}${getDaySuffix(day)} ${month} ${year}`;
    },
  },
};
</script>

<style scoped>
.invoice-info {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 20px; 
}

.invoice-column {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .invoice-info {
    grid-template-columns: 1fr; 
  }
}
</style>