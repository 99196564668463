import { render, staticRenderFns } from "./gotoPage.vue?vue&type=template&id=3f205c3d&scoped=true"
import script from "./gotoPage.vue?vue&type=script&lang=js"
export * from "./gotoPage.vue?vue&type=script&lang=js"
import style0 from "./gotoPage.vue?vue&type=style&index=0&id=3f205c3d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f205c3d",
  null
  
)

export default component.exports