<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal ps-2 pb-2" />
     <form @submit.prevent="submit">
      <section
        :style="{
          'background-image': `url('${getBannerImg}')`,
          'background-repeat': 'no-repeat',
          'background-size': '100%',
          'background-position': 'center',
        }"
        class="
          free_listing-banner
          d-flex
          align-items-center
          justify-content-center
          py-4 mt-2 ms-2
          position-relative
        "
      >
        <div class="container">
          <div class="banner_content-wrapper">
            <div class="banner_image__picker text-center pointer">
              <input
                type="file"
                name="file-input"
                id="file-input"
                class="
                  file-input__input
                  position-absolute
                  overflow-hidden
                  invisible
                "
                @change="onFilebanner"
                ref="banner"
              />
              <label
                class="
                  file-input__label
                  d-inline-flex
                  align-items-center
                  text-white
                  pointer
                  rounded
                  grey-bg
                  py-2
                  px-3
                  f-18
                  text-dark
                "
                for="file-input"
              >
              <template v-if="!banner_url"> 
                <i class="uil-upload f-20 pe-2"></i>
                <span class="f-20 fw-medium">Upload Image</span>
              </template>
               
              </label>
            </div>
          </div>
        </div>
      </section>
      <!-- <img v-if="banner_url" :src="banner_url" /> -->

      <section class="listing_page-body pt-0 pb-5">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-xl-8">
              <div class="card border  mt-3">
                <div class="card-body">
                  <div class="row">
                    <div class="d-flex justify-content-between"> 
                    <div class="over-view__wrapper">
                      <h2 class="gray-text f-18">Restaurant Info</h2>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                      <div class="form-check">
                        <input 
                          class="form-check-input fs-19" 
                          type="checkbox" 
                          v-model="form.is_member"  
                          @change="updateVisitStatus('membership_status')" 
                          id="flexCheckDefault2" 
                        />
                      </div>
                      <label class="form-check-label fs-18 dark-text" for="flexCheckDefault2">
                        OFL Partner
                      </label>
                    </div>
                  </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-Firstname-input">
                          Restaurant Name
                        </label>
                        <input
                          type="text"
                          class="form-control rounded-pill"
                          id="formrow-Firstname-input"
                          v-model="form.title"
                          placeholder="Enter  Restaurant Name"
                        />
                      </div>
                    </div>

                    <!-- <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-email-input"
                          >Email</label
                        >
                        <input
                          type="email"
                          class="form-control rounded-pill"
                          id="formrow-email-input"
                          v-model="form.email"
                          placeholder="Enter Your Email Address"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-OpenDate-input">Restaurant Open Date</label>
                        <input type="date" class="form-control rounded-pill" id="formrow-OpenDate-input"
                          placeholder="Restaurant Open Date" v-model="form.resturant_establish"/>
                      </div>
                    </div> -->
                    <template v-if="form.is_member">
                    <!-- <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-ofl-input">OFL Exclusive Name</label>
                        <input type="text" class="form-control rounded-pill" id="formrow-ofl-input"
                          placeholder="OFL Exclusive Name" v-model = "form.ofl_exclusive"/>
                      </div>
                    </div>
                  
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label class="form-label" for="Exclusive">OFL Exclusive Description</label>
                        <textarea type="text" class="form-control form-control rounded-pill" id="Exclusive"
                          placeholder="OFL Exclusive Description" v-model="form.exclusive_description" />
                      </div>
                    </div> -->
                  </template>
                    <div :class="form.is_member ? 'col-md-6' : 'col-md-6'">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-Contact-input">
                          Contact No.
                        </label>
                        <input
                          type="tel"
                          class="form-control rounded-pill"
                          id="formrow-Contact-input"
                          placeholder="(907) 555-0101"
                          v-model="form.mobile"
                        />
                      </div>
                     
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="formrow-ranking-select">Overall Ranking</label>
                        
                        <select
                          class="form-select rounded-pill"
                          id="formrow-ranking-select"
                          aria-label="select example"
                          v-model="form.ranking"
                        >
                          <option disabled :value=null selected>Select Ranking (1-10)</option>
                          <option :value="null">None</option>
                          <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                        </select>
                      </div>
                    <div class ="col-md-6">
                      <div class="mb-3" v-for="(field, index) in categorySelected" :key="'category-' + index" >
                        <label class="form-label " for="category">Poll Category</label>
                        <multiselect
                          :vModel="formFields[index].category"
                          :multiple="false"
                          :options="categoryOptions"
                          :searchable="true"
                           @search-change="searchCategory"
                          @onChange="changeCategory({...$event,index})"
                          @fetchMore="loadMoreCategories"
                          :class="className"
                        >
                        </multiselect>
                      </div>
                      
                      <a  @click="addField" class="btn bg-transparent orange-text f-18 add-new-cat mb-3">
                      Add another category +
                      </a>
                    </div>
                  
                    <div class = "col-md-6">
                      <div class="mb-3 position-relative" v-for="(field, index) in categorySelected" :key="index">
                          <label class="form-label" for="formrow-ranking-select">Poll Category Rating</label>
                          <select
                           class="form-select form-control rounded-pill"
                            v-model="formFields[index].rating"
                            aria-label="select example"
                          >
                          <option disabled value="">Select Rating (1-10)</option>
                          <option :value="0">None</option>
                          <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                          </select>
                          <div :key="'delete-' + index" class="col-md-12 position-absolute detle-icon">
                            <i 
                              v-if="index != 0" 
                              class="fa fa-trash text-muted del-icon mt-2" 
                              @click="deleteField(index)" 
                              style="cursor: pointer; font-size: 14px; " 
                              aria-hidden="true"
                            ></i>
                            </div>
                       </div>

                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-rating-select">Location Category</label>
                        <select class="form-select form-control rounded-pill" id="formrow-rating-select"
                          aria-label="select example" v-model="locationCategoryNew.id">
                          <option disabled value="" selected>Select location category</option>
                          <option v-for="(locationtype, index) in locationCategories" :key="index" :value="locationtype.id">{{locationtype.title }}</option>
                        </select>
                      </div>
                    </div>
                    <div class ="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-rating-select">Location Category Rating</label>
                        <select class="form-select form-control rounded-pill" id="formrow-rating-select"
                          aria-label="select example" v-model="locationCategoryNew.rating">
                          <option disabled :value=null selected>Select Rating (1-10)</option>
                          <option :value="null">None</option>
                          <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="Website">Website</label>
                        <input
                          type="text"
                          class="form-control rounded-pill"
                          id="Website"
                          placeholder="www.xyz.com"
                          v-model="form.website"
                        />
                      </div>
                      </div>
                      <div class="col-md-6">
                      <div class="mb-3">
                        <label for="category" class="form-label">Price Range</label>
                        <select v-model="form.price_range" id="category" aria-label="select example"
                          class="form-select form-control rounded-pill">
                          <option disabled :value="null">
                            Please Select Price Range
                          </option>
                          <option value="$">
                            $
                          </option>
                          <option value="$$">
                            $$
                          </option>
                          <option value="$$$">
                            $$$
                          </option>
                          <option value="$$$$">
                            $$$$
                          </option>
                        </select>
                      </div>
                      
                    </div>
                    <div class="col-md-6">
                      <!-- Rating Select Field -->
                      

                      <!-- Ranking Select Field -->
                      
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="formrow-rating-select">Primary Location Type</label>
                        <!-- {{patchlocationtype}} -->
                        <select @change="handleSingle" class="form-select form-control rounded-pill"
                          id="formrow-rating-select" aria-label="select example" v-model="form.single">
                          <option disabled value="">Select other locations</option>
                          <option v-for="(locationtype, index) in locationTypes" :key="index" :value="locationtype.id">{{locationtype.title }}</option>
                        </select>
                      </div>
                    <div class="mb-3 col-md-12">
                        <template>
                          <div class="">
                            <label class="form-label" for="formrow-rating-select">Location Type</label>
                            
                            <v-select 
                              v-model="selectedLocationTypes" 
                              :options="locationmultiTypes"
                              label="title" 
                              :getOptionLabel="getOptionLabel" 
                              @input="selectLocationType"
                              class="custom-dropdown "
                              multiple 
                              :close-on-select="false"
                            >
                              <!-- This is how to define the option slot correctly -->
                              <template v-slot:option="option">
                                <div class="d-flex gap-2 align-items-center">
                                  <img :src="option.icon" alt="Icon" width="20" class="option-icon me-2s">
                                  <span>{{ option.title }}</span>
                                </div>
                              </template>
                            </v-select>
                          </div>
                        </template>
                      </div>
                    <!-- <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="overview"
                          >overview</label
                        >
                        <textarea
                          type="text"
                          class="form-control rounded-pill"
                          id="overview"
                          placeholder="www.xyz.com"
                          v-model="form.overview"
                        />
                      </div>
                    </div> -->

                    <!-- <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="sub_category"
                          >Sub Category</label
                        >
                        {{ form.sub_category_id }}
                        <select
                          v-model="form.sub_category_id"
                          class="form-select"
                          id="sub_category"
                          aria-label="select example"
                        >
                          <option :value="null">Select Sub Category</option>
                          <option
                            v-for="(subCategory, index) in subCategories"
                            :key="index"
                            :value="subCategory.id"
                          >
                            {{ subCategory.title }}
                          </option>
                        </select>
                        <multiselect
                          v-model="selected"
                          :multiple="true"
                          :options="options"
                          :preserve-search="true"
                          label="title"
                          track-by="title"
                        >
                        </multiselect>
                      </div>
                    </div> -->
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label class="form-label" for="overview"
                          > Restaurant Description</label
                        >
                        <textarea
                          type="text"
                          class="form-control rounded-pill"
                          id="overview"
                          placeholder="Restaurant Description"
                          v-model="form.overview"
                        />
                      </div>
                    </div>
                  
                  </div>
                  <!-- <input
                      @change="uploadLogo"
                      accept="image/*"
                      type="file"
                      id="logo"
                      class="file-input__input position-absolute overflow-hidden invisible"
                    />
                    <label
                      class="file-input__label d-inline-flex align-items-center text-white pointer rounded grey-bg py-2 px-3 f-18 text-dark"
                      for="logo"
                    >
                      <i class="uil-upload f-20 pe-2"></i>
                      <span class="f-20 fw-medium">Upload Image</span>
                  </label>-->
                  <!--------------------- Select user---------->
                  <!-- <div class="row">
                    <div class="over-view__wrapper col-12">
                      <h2 class="secondary-color f-24">Select User</h2>
                    </div>
                    <div class="col-md-12 col-12">
                      <div class="mb-3">
                        <label for="users" class="form-label">User</label>
                        <select
                          disabled
                          v-model="form.user_id"
                          id="users"
                          aria-label="select example"
                          class="form-select"
                        >
                          <option disabled :value="null">
                            Please Select an User
                          </option>
                          <option
                            v-for="(user, index) in users"
                            :key="index"
                            :value="user.id"
                          >
                            {{ user.full_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div> -->
                  <!--------------------- Select user---------->
                  <div class="row mb-2">
                    <div class="over-view__wrapper col-12">
                      <h2 class="orange-text f-24">Social Profiles</h2>
                    </div>
                    <div class="col-md-4">
                      <label class="form-label mb-0" for="facebook"
                        >Facebook</label
                      >
                      <div class="input-group flex-nowrap my-2">
                        <input
                          type="text"
                          placeholder="Facebook"
                          class="form-control rounded-pill"
                          v-model="form.social.facebook"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label class="form-label mb-0" for="instagram"
                        >Instagram</label
                      >
                      <div class="input-group flex-nowrap my-2">
                        <input
                          type="text"
                          placeholder="Instagram"
                          aria-label="Instagram"
                          aria-describedby="addon-wrapping"
                          v-model="form.social.instagram"
                          class="form-control rounded-pill"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label class="form-label mb-0" for="twitter"
                        >Twitter</label
                      >
                      <div class="input-group flex-nowrap my-2">
                        <input
                          type="text"
                          placeholder="Twitter"
                          aria-label="Twitter"
                          aria-describedby="addon-wrapping"
                          class="form-control rounded-pill"
                          v-model="form.social.twitter"
                        />
                      </div>
                    </div>
                    <!-- <div class="col-md-4">
                      <label class="form-label mb-0" for="google">Yelp</label>
                      <div class="input-group flex-nowrap my-2">
                        <input
                          type="text"
                          placeholder="Yelp"
                          aria-label="Google"
                          aria-describedby="addon-wrapping"
                          class="form-control rounded-pill"
                          v-model="form.social.google"
                        />
                      </div>
                    </div> -->
                  </div>
                  <!--------------------- free listing Page Gallery---------->
                  <div class="features_gallery--wrapper">
                    <h2 class="orange-text f-24">Photo Gallery</h2>
                    <div class="images_wrapper-features-gallery row">
                      <div class="col-md-4">
                        <label for="media" class="f-14 fw-medium text-dark"
                          >Listing Photos*</label
                        >
                        <!-- <UploadMedia :multiple="true" :required="false" @media="Media" /> -->
                        <!-- <UploadImages @changed="handleImages" /> -->
                        <!-- <UpdateMedia
                          :multiple="true"
                          :required="false"
                          @addedMedia="addMedia"
                          server="http://127.0.0.1:8000/api/upload"
                          mediaFilePath="http://127.0.0.1:8000/storage/listing_images"
                          mediaServer
                          @changed="handleImage"
                        ></UpdateMedia> -->
                       
                        <vue-upload-multiple-image
                          @upload-success="uploadImageSuccess"
                          @before-remove="beforeRemove"
                          :data-images="images"
                          :maxImage="10"
                          :showEdit="false"
                          dragText="upload Images"
                          browseText="Not Image Found"
                        ></vue-upload-multiple-image>
                      </div>
                        <!--------------------- free listing Page Video---------->
                        <div class="col-md-8"> 
                          <label  for="media" class="f-14 fw-medium gray-text text-transparent"
                          >Listing Videos*</label
                        >
                          <div class="gallery_video-wrapper">
                         
                           <label for="media" class="f-14 fw-medium gray-text p-3 "
                          > (Max Size 50MB)</label
                        >
                          <div
                            class="
                              video-file-input
                              position-relative
                              text-center
                              rounded
                              pointer
                            "
                          >
                          
                          <span class="text-danger" v-if="videoError">Video Size Too Much(Max 50MB)</span>
                            <input
                              @change="uploadVideo"
                              accept="video/*"
                              type="file"
                              name="file-input"
                              id="file-input-video"
                              class="file-input__input rounded-pill"
                            />
                            <label
                              for="file-input-video"
                              class="
                                file-input__label
                                d-inline-flex
                                align-items-center
                                text-white
                                pointer
                                rounded
                                grey-bg
                                py-2
                                px-3
                                f-18
                                text-dark
                              "
                            >
                              <i class="uil-upload f-20 pe-2"></i>
                              <span class="f-20 fw-medium">Upload Video</span>
                            </label>
                          </div>
                        </div>
                        </div>
                       
                        <div
                          class="features_gallery--wrapper"
                          v-if="this.video_url != null"
                        >
                          <div
                            class="
                              videos-show_main
                              d-flex
                              flex-wrap
                              p-md-4
                              rounded
                            "
                          >
                            <div
                              v-for="(item, index) in video_url"
                              :key="index"
                              class="
                                videos_wrapper-inner
                                col-md-6 col-12
                                position-relative
                              "
                            >
                              <video
                                :src="item.media_path"
                                width="100%"
                                class="video_styles rounded p-2"
                                height="100%"
                                controls
                              ></video>
                              <span
                                @click="removeVideo(index, item.id)"
                                class="video_del-icon pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 40 40"
                                  width="16px"
                                  height="16px"
                                >
                                  <path
                                    fill="#f78f8f"
                                    d="M20,38.5C9.799,38.5,1.5,30.201,1.5,20S9.799,1.5,20,1.5S38.5,9.799,38.5,20S30.201,38.5,20,38.5z"
                                  />
                                  <path
                                    fill="#c74343"
                                    d="M20,2c9.925,0,18,8.075,18,18s-8.075,18-18,18S2,29.925,2,20S10.075,2,20,2 M20,1 C9.507,1,1,9.507,1,20s8.507,19,19,19s19-8.507,19-19S30.493,1,20,1L20,1z"
                                  />
                                  <path
                                    fill="#fff"
                                    d="M18.5 10H21.5V30H18.5z"
                                    transform="rotate(-134.999 20 20)"
                                  />
                                  <path
                                    fill="#fff"
                                    d="M18.5 10H21.5V30H18.5z"
                                    transform="rotate(-45.001 20 20)"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                     
                    </div>
                  </div>
                  <!--------------------- free listing Faq---------->
                  <div class="listing__faq py-0">
                    <div class="faq_inner-listing">
                      <h2 class="orange-text py-2 f-24">
                        Frequently Asked Questions
                      </h2>
                      <div style="padding-bottom: 10px" class="faq_box-wrapper">
                        <div
                          style="margin-bottom: 10px"
                          v-for="(item, index) in faq"
                          :key="index"
                          class="
                            faq_form-box-inner
                          "
                        >
                          <!-- <form class="listing_faq-form"> -->
                          <input
                            type="text"
                            name="listing_faq-question"
                            id="listing_faq-question"
                            placeholder="Question"
                            class="w-100 py-2 form-control rounded-0 rounded-pill"
                            v-model="item.question"
                          />
                          <div class="input-group">
                            <textarea
                              class="form-control mt-3 w-100 rounded-0 rounded-pill"
                              aria-label="With textarea"
                              rows="4"
                              cols="50"
                              id="listing_overview"
                              placeholder="Answer"
                              v-model="item.answer"
                            ></textarea>
                          </div>
                          <!-- <div class="list_add-faq pt-4">
                            <div
                              class="
                                list_add-faq-inner
                                p-3
                                rounded
                                grey-bg
                                pointer
                              "
                            >
                              <h3 class="text-dark f-16 fw-semibold m-0">
                                <i
                                  class="
                                    fa fa-plus fa-solid fa-sharp
                                    text-dark
                                    pe-2
                                    f-20
                                  "
                                ></i
                                >Add Another
                              </h3>
                            </div>
                          </div> -->
                          <!-- <div class="py-2"> <a
                            class="btn btn-danger"
                            v-show="index != 0"
                            @click="removeFaqBox(index)"
                          >
                            Remove
                          </a></div> -->
                          <div class="py-2 float-end me-3  ">
                          <i v-show="index != 0"  @click="removeFaqBox(index)" 
                          class="fa fa-trash text-muted del-icon mt-2"
                             style="cursor: pointer; font-size: 16px; "
                            aria-hidden="true"></i>
                        
                      </div>
                        </div>
                      </div>
                      <div class=""> 
          
                      <a @click="addMore()" class="btn bg-tranparent orange-text f-18 p-0">Add +</a>
                      </div>
                     
                    </div>
                  </div>
                  <div class="dish_inner-listing py-4">
                    <h2 class="orange-text py-2 f-24"> Menu links</h2>
                    <div class="col-md-12 mb-3">
                            <input type="text" name="menu_link" id="menu_link" v-model="manu_link" placeholder="Menu Link"
                              class="w-100 py-2 form-control rounded-0 rounded-pill" />
                          </div>

                          <!-- <div style="padding-bottom: 10px" class="dish_box-wrapper">
                      <div
                        style="margin-bottom: 10px"
                        v-for="(dish, index) in getDishes"
                        :key="`dishitem-${index}`"
                        class="dish_form-box-inner"
                      >
                     
                        <div class="form-group mb-3">
                          <input
                            type="file"
                            class="form-control rounded-pill"
                            id="dish_image"
                            @change="onImageChange($event, index)"
                          />
                          <img
                            v-show="dish.previewImage"
                            :key="`dish-image-${index}`"
                            :src="dish.previewImage"
                            alt="Dish Image Preview"
                            class="img-fluid mt-2"
                            :id="`dish-${index}`"
                            style="max-height: 150px;"
                          />
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-3">
                              <input
                                type="text"
                                name="dish_name"
                                id="dish_name"
                                placeholder="Dish Name"
                                class="w-100 py-2 form-control rounded-0 rounded-pill"
                                v-model="dish.name"
                              />
                            </div>

                            <div class="col-md-6 mb-3">
                              <input
                                type="text"
                                name="dish_name"
                                id="dish_name"
                                placeholder="Dish Price"
                                class="w-100 py-2 form-control rounded-0 rounded-pill"
                                v-model="dish.price"
                              />
                            </div>
                          </div>
                       
                        <div class="py-2 float-end me-3  " >
                          <i v-show="index != 0"  @click="removeDishBox(index,dish.id)" 
                          class="fa fa-trash text-muted del-icon mt-2"
                             style="cursor: pointer; font-size: 16px; "
                            aria-hidden="true"></i>
                        
                      </div>
                      </div>
                    </div>
                    <div class=""> 
        
                      <a @click="addMoreDish()" class="btn bg-tranparent orange-text f-18 p-0">Add +</a>
                    </div> -->
                    
                  </div>
                  <!-- hide menu  -->
                  <div class="dish_inner-listing pb-4" v-if="form.is_member">
                    <h2 class="gray-text f-18 fw-600">Secret Menu </h2>

                    <div style="padding-bottom: 10px" class="dish_box-wrapper">
                      <div style="margin-bottom: 10px" v-for="(secret, index) in getMenus" :key="`secretitem-${index}`"
                        class="dish_form-box-inner border-secondary rounded pt-3">
                        <!-- Dish Image Upload -->
                        <div class="form-group mb-3">
                          <label for="secret_image" class="form-label"></label>
                          <input
                            type="file"
                            class="form-control rounded-pill"
                            id="secret_image"
                            @change="onSecretImageChange($event, index)"
                          />

                            <img
                                v-show="secret.previewImage"
                                :key="`secret-image-${index}`"
                                :src="secret.previewImage"
                                alt="Secret Menu Image Preview"
                                class="img-fluid mt-2"
                                :id="`secret-${index}`"
                                style="max-height: 150px;"
                              />
                        </div>

                        <!-- Dish Name -->
                        <div class="row">
                          <div class="col-md-6 mb-3">
                            <input type="text" name="dish_name" id="dish_name" placeholder="Name"
                              class="w-100 py-2 form-control rounded-0 rounded-pill" v-model="secret.name" />
                          </div>
                          <div class="col-md-6 mb-3">
                            <input type="text" name="dish_name" id="dish_name" placeholder="Description"
                              class="w-100 py-2 form-control rounded-0 rounded-pill" v-model="secret.description" />
                          </div>
                        </div>
                        <div class="py-2 float-end me-3  " v-if ="index > 0">
                          <i v-show="index != 0"  @click="removeSecretMenu(index,secret.id)" class="fa fa-trash text-muted del-icon mt-2"
                             style="cursor: pointer; font-size: 16px; "
                            aria-hidden="true"></i>
                        
                      </div>
                        </div>
                        
                        </div>
                     
                      <div>
                     <a @click="addSecret()" class="btn bg-tranparent orange-text f-18 p-0">Add +</a>
                     </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-xl-4">
              <div class="card border mt-3">
                <div class="card-body">
                  <!-- <form class="side-bar_listing-form"> -->
                  <!--------------------- free listing Sidemenu Location---------->
                  <div class="listings-location_main rounded">
                    <div class="location_inner-wrappper rounded">
                      <div class="location_info-wrapper">
                        <div class="row">
                          <div class="col-md-12 mb-3">
                            <AddressFields
                              :modelValue="address"
                              @update:modelValue="address = $event"
                            />
                            <!-- <label class="form-label" for="street address">
                                Street
                                Address
                            </label>-->
                            <!-- <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Street Address"
                                  aria-label="Street Address"
                                  v-model="form.address"
                                  aria-describedby="addon-wrapping"
                                />
                            </div>-->
                          </div>
                        </div>
                        <div class="row">
                          <!-- <div class="col-md-12">
                              <label class="form-label" for="City">City</label>
                          </div>-->
                          <!-- <div class="col-md-12 mb-3">
                              <div class="listing-state">
                                <input
                                  type="text"
                                  name="location_listing-city"
                                  placeholder="Business Email"
                                  class="form-control w-100"
                                  v-model="form.city"
                                />
                              </div>
                          </div>-->
                          <!-- <div class="col-md-12">
                              <label class="form-label" for="State">State</label>
                          </div>-->
                          <!-- <div class="col-md-12 mb-3">
                              <div class="listing-state">
                                <input
                                  type="text"
                                  name="location_listing-city"
                                  placeholder="Business Email"
                                  v-model="form.state"
                                  class="form-control w-100"
                                />
                              </div>
                            </div>
                            <div class="col-md-12">
                              <label class="form-label" for="County">County</label>
                            </div>
                            <div class="col-md-12 mb-3">
                              <div class="listing-state">
                                <input
                                  type="text"
                                  name="location_listing-city"
                                  placeholder="Business Email"
                                  class="form-control w-100"
                                  v-model="form.country"
                                />
                              </div>
                            </div>
                            <div class="col-md-12">
                              <label class="form-label" for="Zip Code">Zip Code</label>
                            </div>
                            <div class="col-md-12 mb-3">
                              <div class="listing-state">
                                <input
                                  type="text"
                                  name="location_listing-city"
                                  placeholder="Business Email"
                                  class="form-control w-100"
                                  v-model="form.zip_code"
                                />
                              </div>
                          </div>-->
                          <div class="">
                            <div class="listing-state">
                              <!-- <select
                                  name="listings_states"
                                  id="cars"
                                  class="form-select w-100 form-control"
                                >
                                  <option value="volvo">TX</option>
                                  <option value="saab">CA</option>
                                  <option value="opel">OP</option>
                                  <option value="audi">CT</option>
                              </select>-->
                            </div>
                          </div>
                          <div class="">
                            <div class="listing-country">
                              <!-- <select
                                  name="listings_country"
                                  id="cars"
                                  class="form-select w-100 form-control"
                                >
                                  <option value="volvo">US</option>
                                  <option value="saab">AU</option>
                                  <option value="opel">TI</option>
                              </select>-->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--------------------- free listing Sidemenu HOurs---------->
                  <div class="listings-hours_main my-3">
                    <div class="location_listing-hours-wrapper rounded ">
                      <div class ="d-flex justify-content-between">
                     <div>
                      <input
                      @click="hoursStatus"
                            class="form-check-input"
                            v-model="form.availability_hours"
                            type="checkbox"
                            id="availibilityHours"
                          />
                       
                          
                          <label
                            class="features-label form-check-label f-14 pointer ps-2"
                            for="availibilityHours"
                            >Availibility Hours</label
                          >
                           </div>
                          <div>
                          <input  
                          @click="openHours"
                          class="form-check-input" v-model="form.openHours"
                          type="checkbox" id="open24Hours" />
                          <label class="features-label form-check-label text-secondary f-14 pointer ps-2"
                          for="open24Hours">Open 24 Hours</label>
                          </div>
                          </div>
                      <div
                        class="
                          hours-header
                          row
                          justify-content-between
                          align-items-center
                          py-2
                        "
                      >
                        <div v-if="form.availability_hours" class="col-4">
                          <!-- <h2 class="text-dark f-16 fw-bold"></h2> -->
                        </div>
                      </div>


                      <div v-if="form.availability_hours" class="hours-listing-body">
                    <div v-for="(day, index) in days" :key="index" class="hours-body-inner row align-items-center">
                      <!-- Day Name -->
                      <div
                        class="
                          col-12
                          text-start
                          py-1 py-lg-0
                          col-xl-12
                          col-md-4
                          f-16
                          text-dark
                        "
                      >
                        <span>{{ day }}</span>
                      </div>

                      <div class="col-6 col-xl-6 col-md-4">
                        <input
                          class="border rounded-pill pointer p-1 time-field w-100"
                          type="time"
                          v-model="time[day.toLowerCase()].from"
                        />
                      </div>

                      <div class="col-6 col-xl-6 col-md-4">
                        <input
                          class="border rounded-pill pointer p-1 time-field w-100"
                          type="time"
                          v-model="time[day.toLowerCase()].to"
                        />
                      </div>

                      <div class="d-flex justify-content-end align-items-center pt-1 me-3">
                        <i
                          class="fas fa-plus-circle pe-1"
                          style="cursor: pointer;"
                          @click="addTimeSlot(day)"
                        ></i>
                      </div>
                      <template  v-if="time[day.toLowerCase()].additionalSlots.length > 0">
                      <div
                        v-for="(slot, slotIndex) in time[day.toLowerCase()].additionalSlots"
                        :key="slotIndex"
                        class="row g-2 align-items-center mt-2"
                      >
                        <div class="col-6 col-xl-6 col-md-4">
                          <input
                            class="border rounded-pill pointer p-1 time-field w-100"
                            type="time"
                            v-model="slot.from"
                          />
                        </div>
                        <div class="col-6 col-xl-6 col-md-4">
                          <input
                            class="border rounded-pill pointer p-1 time-field w-100"
                            type="time"
                            v-model="slot.to"
                          />
                        </div>
                        <div class="d-flex justify-content-end align-items-center pt-1 me-3" >
                        <i
                          class="fas fa-trash-alt ps-2"
                          style="cursor: pointer;"
                          @click="removeTimeSlot(day, slotIndex)"
                        ></i>
                      </div>
                      </div>
                    </template>
                    </div>
                    </div>
                      <!-- <div v-if="form.availability_hours" class="hours-listing-body">
                       
                        <div
                          class="hours-body-inner row align-items-center my-2"
                        >
                          <div
                            class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              col-xl-12
                              f-16
                              text-dark
                            "
                          >
                            <span>Monday</span>
                          </div>
                          
                          <div class="col-6 col-md-4 col-xl-6">
                            <input
                              class="pointer p-1 border rounded-pill w-100"
                              id="monday-start-time"
                              type="time"
                              name="start-time"
                              v-model="time.mondayFrom"
                              value="00:00"
                            />
                          </div>
                          <div class="col-6 col-md-4 col-xl-6">
                            <input
                              class="pointer p-1 border rounded-pill w-100"
                              id="appt-end-time"
                              type="time"
                              v-model="time.mondayTo"
                              name="end-time"
                              value="00:00"
                            />
                          </div>
                        </div>
                        <div
                          class="hours-body-inner row align-items-center my-2"
                        >
                          <div
                            class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              col-xl-12
                              f-16
                              text-dark
                            "
                          >
                            <span>Tuesday</span>
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input
                              class="pointer p-1 border rounded-pill w-100"
                              id="appt-start-time"
                              type="time"
                              name="start-time"
                              v-model="time.tuesdayFrom"
                              value="00:00"
                            />
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input
                              class="pointer p-1 border rounded-pill w-100"
                              id="appt-end-time"
                              type="time"
                              name="end-time"
                              v-model="time.tuesdayTo"
                              value="00:00"
                            />
                          </div>
                        </div>
                        <div
                          class="hours-body-inner row align-items-center my-2"
                        >
                          <div
                            class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              col-xl-12
                              f-16
                              text-dark
                            "
                          >
                            <span>Wednesday</span>
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input
                              class="pointer p-1 border rounded-pill w-100"
                              id="appt-start-time"
                              type="time"
                              name="start-time"
                              v-model="time.wednesdayFrom"
                              value="00:00"
                            />
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input
                              class="pointer p-1 border rounded-pill w-100"
                              id="appt-end-time"
                              type="time"
                              v-model="time.wednesdayTo"
                              name="end-time"
                              value="00:00"
                            />
                          </div>
                        </div>
                        <div
                          class="hours-body-inner row align-items-center my-2"
                        >
                          <div
                            class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              col-xl-12
                              f-16
                              text-dark
                            "
                          >
                            <span>Thursday</span>
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input
                              class="pointer p-1 border rounded-pill w-100"
                              id="appt-start-time"
                              type="time"
                              v-model="time.thursdayFrom"
                              name="start-time"
                              value="00:00"
                            />
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input
                              class="pointer p-1 border rounded-pill w-100"
                              id="appt-end-time"
                              type="time"
                              name="end-time"
                              v-model="time.thursdayTo"
                              value="00:00"
                            />
                          </div>
                        </div>
                        <div
                          class="hours-body-inner row align-items-center my-2"
                        >
                          <div
                            class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              col-xl-12
                              f-16
                              text-dark
                            "
                          >
                            <span>Friday</span>
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input
                              class="pointer p-1 border rounded-pill w-100"
                              id="appt-start-time"
                              type="time"
                              v-model="time.fridayFrom"
                              name="start-time"
                              value="00:00"
                            />
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input
                              class="pointer p-1 border rounded-pill w-100"
                              id="appt-end-time"
                              type="time"
                              v-model="time.fridayTo"
                              name="end-time"
                              value="00:00"
                            />
                          </div>
                        </div>
                        <div
                          class="hours-body-inner row align-items-center my-2"
                        >
                          <div
                            class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              col-xl-12
                              f-16
                              text-dark
                            "
                          >
                            <span>Saturday</span>
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input
                              class="pointer p-1 border rounded-pill w-100"
                              id="appt-start-time"
                              type="time"
                              v-model="time.saturdayFrom"
                              name="start-time"
                              value="00:00"
                            />
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input
                              class="pointer p-1 border rounded-pill w-100"
                              id="appt-end-time"
                              type="time"
                              v-model="time.saturdayTo"
                              name="end-time"
                              value="00:00"
                            />
                          </div>
                        </div>
                        <div
                          class="hours-body-inner row align-items-center my-2"
                        >
                          <div
                            class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              col-xl-12
                              f-16
                              text-dark
                            "
                          >
                            <span>Sunday</span>
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input
                              class="pointer p-1 border rounded-pill w-100"
                              id="appt-start-time"
                              type="time"
                              v-model="time.sundayFrom"
                              name="start-time"
                              value="00:00"
                            />
                          </div>
                          <div class="col-6 col-xl-6 col-md-4">
                            <input
                              class="pointer p-1 border rounded-pill w-100"
                              id="appt-end-time"
                              type="time"
                              v-model="time.sundayTo"
                              name="end-time"
                              value="00:00"
                            />
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                  <div class="d-flex justify-content-center"> 
                  <div class="cta_submit text-center border-orange rounded-pill p1 fit-content">
                    <SubmitButton
                      :processing="processing"
                      type="submit"
                      class="
                        btn btn-primary
                        waves-effect waves-light
                        
                        px-5
                        
                      "
                      text="Update Restaurant"
                    />
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
// import { UploadMedia, UpdateMedia } from "vue-media-upload";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import Swal from "sweetalert2";
import AddressFields from "../AddressFields.vue";
import Multiselect from "../../../../components/general/multiSelect.vue";
import vSelect from 'vue-select'
export default {
  page: {
    title: "Update Business",
    meta: [
      {
        name: "approved",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    AddressFields,
    // UpdateMedia,
    Multiselect,
    vSelect,
    VueUploadMultipleImage,
  },
  data() {
    return {
      days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      time: {
        monday: { from: null, to: null, additionalSlots: [] },
        tuesday: { from: null, to: null, additionalSlots: [] },
        wednesday: { from: null, to: null, additionalSlots: [] },
        thursday: { from: null, to: null, additionalSlots: [] },
        friday: { from: null, to: null, additionalSlots: [] },
        saturday: { from: null, to: null, additionalSlots: [] },
        sunday: { from: null, to: null, additionalSlots: [] },
      },
      locationCategories: [],
      locationmultiTypes:[],
      categoryRating:null,
      formFields: [
      { category: "", rating: "" },
    ],
      processing: false,
      categoryOptions:[],
      categorySelected:[],
      selected: [],
      options: [],
      manu_link:"",
      dishes: [{ image: null, 
        name: '', 
        price:'',
        type: '',
        id:null,
        previewImage: null }
      ],
      secretMenu: [{
        image: null,
        name: '',
        description: '',
        id:null,
        previewImage: null,

      }], 
      dish_media:[],
      videoError:false,
      title: "Edit  Restaurant",
      items: [
        {
          text: "Omaha",
        },
        {
          text: "Edit  Restaurant",
          active: true,
        },
      ],
      faq: [],
      // time: {
      //   mondayFrom: null,
      //   mondayTo: null,
      //   tuesdayFrom: null,
      //   tuesdayTo: null,
      //   wednesdayFrom: null,
      //   wednesdayTo: null,
      //   thursdayFrom: null,
      //   thursdayTo: null,
      //   fridayFrom: null,
      //   fridayTo: null,
      //   saturdayFrom: null,
      //   saturdayTo: null,
      //   sundayFrom: null,
      //   sundayTo: null,
      // },
      locationCategoryNew:{
        id:'',
        rating:null,
      },
      video_url: [],
      banner_url: null,
      banner: "",
      logo: "",
      video: [],
      categories: null,
      subCategories: null,
      users: null,
      address: {
        city: null,
        state: null,
        country: null,
        address: null,
        zipcode: null,
        latitude:null,
        longitude:null,
        place_id:null,
      },
      images: [],
      allFeature: [],
      feature: [],
      savedMdia: [],
      locationTypes:[],
      selectedLocationTypes: [],
      newUploadMedia: [],
      media: [],
      form: {
        longitude: null,
        latitude: null,
        time: {},
        price_range:null,
        single:null,
        is_member:null,
        ofl_exclusive: null,
        resturant_establish: null,
        exclusive_description:null,
        availability_hours:true,
        openHours: false,
        city: null,
        state: null,
        country: null,
        address: null,
        place_id:null,
        title: null,
        email: null,
        mobile: null,
        website: null,
        racdting:null,
        ranking:null,
        selectedLocationTypeIds:[],
        category_id: [],
        user_id: null,
        overview: null,
        social: {
          facebook: null,
          instagram: null,
          twitter: null,
          google: null,
        },
        // time: {
        //   monday: null,
        //   tuesday: null,
        //   wednesday: null,
        //   thursday: null,
        //   friday: null,
        //   saturday: null,
        //   sunday: null,
        // },
       
      },
    };
  },
  mounted() {
    this.locationCategory();
    this.videoOnly();
    this.mediaOnly();
    this.singleList();
    this.allCategory();
    this.allUser();
    this.allFeatures();
    this.allLocationType();
  },
  computed:{
      getBannerImg(){
        return this.banner_url;
        },
      getDishes(){
        return this.dishes;
      },
      getMenus(){
        return this.secretMenu;
      }
  },
  methods: {
    addTimeSlot(day) {
      this.time[day.toLowerCase()].additionalSlots.push({ from: "00:00", to: "00:00" });
    },
    removeTimeSlot(day, index) { 
      this.time[day.toLowerCase()].additionalSlots.splice(index, 1);
    },
    debounce(func, delay) {
      let timer;
      return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(this, args), delay);
      };
    },
    searchCategory: function(query) {
      this.$axios
        .get("search-category", { params: { query } })
        .then((response) => {
          this.categoryOptions = response.data;
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    },
    locationCategory() {
      this.$axios
        .get("location-category", { params: { page_size: 100 } })
        .then((response) => {
          // console.log()

          this.locationCategories = response.data.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    handleSingle(event) {    
      let tempLocation =this.form.selectedLocationTypeIds;
      this.locationmultiTypes = [];
      if (this.form.selectedLocationTypeIds.length) {
        this.selectedLocationTypes = [];
      }
      if (!tempLocation.includes(this.form.single)) {
        tempLocation=(this.form.single);
      }
      
      this.form.selectedLocationTypeIds =[tempLocation];
      this.locationmultiTypes = this.locationTypes.filter(item => item.id !== this.form.single);
    },
    updateVisitStatus(statusType) {
      const status = this.form.is_member ? '1' : '0';
  // Perform additional functionality using `status`
},
    loadMoreCategories(){
      if (this.categoryNextPageUrl) {
         this.allCategory(this.categoryNextPageUrl)
      } else {
        console.log("No more categories to load.");
      }
     },
    addField() {
  // if (!Array.isArray(this.slug)) {
  //   this.categorySelected = [];
  // }
  // {category:item.id,rating:item.pivot.rating}

  // categoryRating[index].pivot.rating
  this.categorySelected.push({ id:"",title:"",icon:"",slug:"" });
  this.formFields.push({category:'',rating:''})
},
deleteField(index) {
    if (this.categorySelected.length > 1) {
      this.categorySelected.splice(index,1);
      this.formFields.splice(index, 1);
    }
  },
    allFeatures() {
      this.$axios
        .get("feature")
        .then((response) => {
          this.allFeature = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    videoOnly() {
      this.$axios
        .get("video/" + this.$route.params.id)
        .then((response) => {
          // response.data.forEach((element) => {
          //   this.video_url.push(element.media_path);
          // });

          this.video_url = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    mediaOnly() {
  this.$axios.get("update/" + this.$route.params.id).then((response) => {
    response.data.media.forEach((item) => {
      // Ensure `path` includes the full URL
      let media = {
        path: `${item.name}`, // Adjust the path based on your server setup
        default: 1,
        highlight: 1,
      };
      this.images.push(media);
    });
    console.log(this.images); // Debugging
  });
},
    userPersonalData(data) {
      this.form.email = data.email;
      this.form.title = data.title;
      this.form.mobile = data.mobile;
      this.form.website = data.website;
      this.form.overview = data.overview;
      this.form.price_range = data.price_range;
      // this.form.rating=data.rating
      this.form.ranking=data.ranking
      this.form.is_member = data.is_member == '1' ? true : false;
      this.form.resturant_establish = data.resturant_establish
      this.form.exclusive_description = data.exclusive_description
      this.form.ofl_exclusive = data.ofl_exclusive
      this.manu_link = data.manu_link
    },
    singleList() {
      let self = this;
      this.$axios
        .get("single-business-listing/" + this.$route.params.id)
        .then((response) => {
          this.formFields=[];
          if(response.data.data.open_24_hours == 1) {
            this.form.openHours = true;
            this.hoursStatus();
          }

          let data = response.data.data.categories;          
          this.locationCategoryNew.id = response.data.data.location_categories[0]?.id;
          this.locationCategoryNew.rating = response.data.data.location_categories[0]?.pivot.rating;
          
          this.categoryRating = data;
          this.categorySelected = data.map(({ pivot, ...rest }) => rest);
          this.categoryRating.map((item,index) => {
            this.formFields.push({category:this.categorySelected[index],rating:item.pivot.rating});
          });
          var uniq = {};
          var arrFiltered = this.categorySelected.filter(obj => !uniq[obj?.id] && (uniq[obj?.id] = true));
          this.categorySelected = arrFiltered;
          if(response.data.data?.faqs){

            response.data.data.faqs.forEach((element) => {
              let newObj = {};
              newObj.question = element.question;
              newObj.answer = element.answer;
              this.faq.push(newObj);
            });
          }

          //banner url
          this.banner_url = response.data.data.banner_url;
          //user Personal data
          this.userPersonalData(response.data.data);
          //user id
          this.form.user_id = response.data.data.user_id;
          //listing features
          response.data.data.features.forEach((element) => {
            this.feature.push(element.id);
          });
          // console.log(this.feature);
          // console.log(this.feature);
          //listing category & subcategory
          // this.form.sub_category_id = response.data.data.sub_categories[0].id;
          this.form.category_id = response.data.data.category_id;
          this.getAllSubCategories(this.form.category_id);
          //listing addresss
          self.address.address = response.data.data?.address?.address;
          this.address.country = response.data.data?.address?.country;
          this.address.city = response.data.data?.address?.city;
          this.address.state = response.data.data?.address?.state;
          this.address.zipcode = response.data.data?.address?.zipcode;
          this.address.latitude = response.data.data?.address?.latitude;
          this.address.longitude = response.data.data?.address?.longitude;

          this.form.single = response.data.data?.location_types[0]?.id;
          let locationData = response.data.data?.location_types;
          this.form.selectedLocationTypeIds =  locationData.map(option => option.id);
          this.selectedLocationTypes= locationData.filter(item => item.id !== this.form.single);
                                   
          response.data.data?.social_media.forEach((element) => {
            self.form.social[element.social_media_type] =
              element.social_media_url;
          });
          //listing available time
         
          let time = response.data.data?.listing_time;
          time.forEach(entry => {
            const day = entry.day.toLowerCase();
            const hours = entry.hours.split(", ").map(hour => hour.trim());
         
            if (!this.time[day]) {
              this.time[day] = { from: null, to: null, additionalSlots: [] };
            }

            hours.forEach((slot) => {
              let normalizedSlot = slot;
              if (slot.includes('–')) {
                normalizedSlot = slot.replace('–', '-');
              }
              const [from, to] = normalizedSlot.split("-");

              if (this.time[day].from === null) {
                this.time[day].from = from;
                this.time[day].to = to;
              } else {
                const duplicate = this.time[day].additionalSlots.some(existingSlot => existingSlot.from === from && existingSlot.to === to);
                if (!duplicate) {
                  this.time[day].additionalSlots.push({ from, to });
                }
              }
            });
          });

          // time.map(item => {
          //   if (item.hours !== 'null-null') {
          //     this.availability_hours=true;
          //   }
          // })
          // this.time.mondayFrom = time[0].hours.split("-")[0];
          // this.time.mondayTo = time[0].hours.split("-")[1];
          // this.time.tuesdayFrom = time[1].hours.split("-")[0];
          // this.time.tuesdayTo = time[1].hours.split("-")[1];
          // this.time.wednesdayFrom = time[2].hours.split("-")[0];
          // this.time.wednesdayTo = time[2].hours.split("-")[1];
          // this.time.thursdayFrom = time[3].hours.split("-")[0];
          // this.time.thursdayTo = time[3].hours.split("-")[1];
          // this.time.fridayFrom = time[4].hours.split("-")[0];
          // this.time.fridayTo = time[4].hours.split("-")[1];
          // this.time.saturdayFrom = time[5].hours.split("-")[0];
          // this.time.saturdayTo = time[5].hours.split("-")[1];
          // this.time.sundayFrom = time[6].hours.split("-")[0];
          // this.time.sundayTo = time[6].hours.split("-")[1];

          this.dishes = response.data.data.dishes;
          for (let index = 0; index < this.dishes.length; ++index) {
            if(!this.dishes[index].previewImage) {
             this.dishes[index].previewImage = this.dishes[index].media[0]?.media_path;
            }
          }
          this.secretMenu = response.data.data.secret_menus;
         
          for (let index = 0; index < this.secretMenu?.length; ++index) {
            if(!this.secretMenu[index].previewImage) {
              this.secretMenu[index].previewImage = this.secretMenu[index]?.media[0]?.media_path;
             }
          }

          // response.data.data.dishes.forEach((element) => {
          //   self.form.social[element.social_media_type] =
          //     element.social_media_url;
          // });
          // this.dishes.previewImage
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    onFilebanner(e) {
      const file = e.target.files[0];
      this.banner = this.$refs.banner.files[0];
      // console.log(this.banner);
      this.banner_url = URL.createObjectURL(file);
    },
    selectMedia(e) {
      const file = e.target.files[0];
      // console.log(file);
    },
    submit() {
      // return true;
      console.log("helooooooo");
      this.processing = true;
      this.form.category_id = this.categorySelected.map(element => element.id);
      this.form.state = this.address.state;
      this.form.country = this.address.country;
      this.form.address = this.address.address;
      this.form.city = this.address.city;
      this.form.place_id = this.address.place_id;
      this.form.longitude = this.address.longitude;
      this.form.latitude = this.address.latitude;

      
          this.form.openHours;
        
      if (this.form.availability_hours) {
        
          const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

          days.forEach((day) => {
            const dayLower = day.toLowerCase();
            const allSlots = [];

            const mainSlot = `${this.time[dayLower].from}-${this.time[dayLower].to}`;
            allSlots.push(mainSlot);

            const additionalSlots = this.time[dayLower].additionalSlots.map(slot => `${slot.from}-${slot.to}`);
            allSlots.push(...additionalSlots);

            this.form.time[dayLower] = allSlots.join(", ");
          });

        
      }

      let formData = new FormData();
      for (var i = 0; i < this.video.length; i++) {
        let file = this.video[i];
        formData.append("video[" + i + "]", file);
      }
      console.log(this.address);
      formData.append("banner", this.banner);
      formData.append("address", JSON.stringify(this.address));
      formData.append("form", JSON.stringify(this.form));
      formData.append("faq", JSON.stringify(this.faq));
      formData.append("feature", JSON.stringify(this.feature));
      formData.append("dishes", JSON.stringify(this.dishes));
      formData.append('location_category',JSON.stringify(this.locationCategoryNew));
      formData.append("secret_menu", JSON.stringify(this.secretMenu));
      formData.append("manu_link", this.manu_link);
      for (let i = 0; i < this.dishes.length; i++) {
        formData.append(`dish_media[${i}]`, this.dishes[i].image);
      }
          this.formFields.forEach((field, index) => {
          const rating = field.rating || 0;
          formData.append(`categories[${index}]`, field.category.id); 
          formData.append(`rating[${index}]`, rating); 
         });
      
         for (let i = 0; i < this.secretMenu.length; i++) {
        formData.append(`secret_media[${i}]`, this.secretMenu[i].image);
      }
      this.$axios
        .post("update-business-listing/" + this.$route.params.id, formData)
        .then((response) => {
          // console.log(response.data);
          this.triggerSwal(response.data.message, "success");
          this.processing = false;
          
          const query = {};

          if (this.$route.query.currentPage) {
            query.currentPage = this.$route.query.currentPage;
          }
          if (this.$route.query.search) {
            query.search = this.$route.query.search;
          }
          if (this.$route.query.is_member) {
            query.is_member = this.$route.query.is_member;
          }

          if (this.$route.query.page_size) {
            query.pageSize = this.$route.query.page_size;
          }

          this.$router.push({
            path: '/business/listing',
            query,
          });

        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
          // console.log(error);
        }).
        finally((error) => {
          this.processing = false;
        });
    },
    allCategory(url) {
      let endPoint = url?url :"categories";
      this.$axios
        .get(endPoint)
        .then((response) => {
          this.categories = response.data.data.data;
          let allCategories = response.data.data.data;
          this.categoryOptions = url ?  [...this.categoryOptions , ...allCategories] : allCategories;
          this.categoryNextPageUrl = response.data.data.next_page_url;
          //   console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    changeCategory({event , index}) {
      console.log(event,index,'event');
      
      // console.log(this.formFields,'on change');
         this.formFields[index].category = event; 

         console.log(this.formFields,'form fields');

      // this.categorySelected.forEach(element=>{
      //   this.options=[];
      //   this.$axios
      //   .get("categories/" + element.id)
      //   .then((response) => {
      //     // this.subCategories = response.data.data;
      //     response.data.data.forEach(element=>{
      //       this.categoryOptions.push(element);
      //     })
      //   })
      //   .catch((error) => {
      //     console.log(error.response);
      //   });
      // })
    },
    allUser() {
      this.$axios
        .get("all-users-for-list")
        .then((response) => {
          this.users = response.data.data;
          //   console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    uploadLogo(e) {
      this.logo = e.target.files[0];
    },
    uploadVideo(e) {
      let video = e.target.files[0];
      if (video.size >= 50000000) {
        return this.videoError=true
      }
      this.videoError=false;
      let temp_video_url = URL.createObjectURL(video);
      const obj = {};
      obj.media_path = temp_video_url;
      obj.id = this.video_url.length + 1;
      this.video_url.push(obj);
      console.log(this.video_url);
      this.video.push(video);
    },
    uploadImageSuccess(formData, index, fileList) {
      formData.append("id", this.$route.params.id);
      var self = this;
      self.$axios
        .post("/update-images", formData)
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
          // this.handleErrorResponse(error.response, "error");
        });
    },
    beforeRemove(index, done, fileList) {
      console.log(this.images,"images")
      
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const imageToDelete = this.images[index];
      console.log(imageToDelete,'---');
      const lastSegment = imageToDelete.path.split("/").pop()
      console.log(lastSegment, 'last segment')
      try {
        await this.$axios.post("delete-images", {
          fileName: "/storage/listing_images/" + lastSegment,
          id: this.$route.params.id,
        });
        this.images.splice(index, 1);
        done();
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      } catch (error) {
        console.error("Error deleting image:", error);
        Swal.fire("Error!", "There was an issue deleting the file.", "error");
      }
    } else {
      done(false);
    }
  });
},
    getAllSubCategories(id) {
      // alert(id);
      this.$axios
        .get("all-subCategories/" + id)
        .then((response) => {})
        .catch((error) => {
          console.log(error.response);
        });
    },
    removeVideo(index, id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var completeObject = this.video_url[index];
          if (completeObject.media_path.indexOf("videos") > -1) {
            var parts = completeObject.media_path.split("/");
            var lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash
            this.$axios
              .post("delete-images", {
                fileName: "/storage/videos/" + lastSegment,
                id: this.$route.params.id,
              })
              .then((response) => {
                console.log(response.data);
                this.video_url.splice(index, 1);
              });
            // });
          } else {
            this.video_url.splice(index, 1);
          }
        }
      });

      // console.log(lastSegment);
    },
    addMoreDish() {
      this.dishes.push({ image: null, name: '', type: '', previewImage: null,id:null});
    },
    removeDishBox(index,id) {
      if(id)
      {
        Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$axios
        .get("delete/vendor/dish/" + id)
        .then((response) => {
          if(response.data.success)
        {
          this.dishes.splice(index, 1);
        }
        });
        }
      });
      }
      this.dishes.splice(index, 1);
    },
    addSecret(){
      this.secretMenu.push({ image: null, name: '', description: '' , previewImage:null });
    },

    removeSecretMenu(index,id) {
      if(id)
      {
        Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$axios
        .get("delete/vendor/secret-menu/" + id)
        .then((response) => {
          if(response.data.success)
        {
          this.secretMenu.splice(index, 1);
        }
        });
        }
      });
      }
      this.secretMenu.splice(index, 1);
    },
    // removeSecretMenu(index) {
    //   this.secretMenu.splice(index, 1);
    // },
    onImageChange(event, index) {
        const file = event.target.files[0];
         if (file) {
          this.$set(this.dishes, index, {
            ...this.dishes[index], 
            image: file, 
           previewImage: URL.createObjectURL(file), 
     });

      let dishImage = document.getElementById(`dish-${index}`);
      dishImage.classList.add('d-block');
    }
  },
  onSecretImageChange(event, index) {
  const file = event.target.files[0];
  if (file) {
    const previewImage = URL.createObjectURL(file);
    this.$set(this.secretMenu, index, {
      ...this.secretMenu[index],
      image: file,
      previewImage: previewImage,
    });

    this.$nextTick(() => {
      let secretImage = document.getElementById(`secret-${index}`);
      if (secretImage) {
        secretImage.classList.add("d-block");
      }
    });
  }
}

,

    removePreviewImage(index) {
      this.getDishes[index].previewImage = null;
    },
    removeFaqBox(index) {
      this.faq.splice(index, 1);
    },
    addMore() {
      this.faq.push({
        question: null,
        answer: null,
      });
    },
    hoursStatus() {
      this.form.availability_hours = !this.form.availability_hours;
      if (this.form.availability_hours) {
      this.form.openHours = false;
    }
    },
    openHours() {
      this.form.openHours = !this.form.openHours;
      if (this.form.openHours) {
      this.form.availability_hours = false;
    }
    },
    allLocationType() {
      this.$axios
        .get("location-type",{ params: { page_size: 100 } })
        .then((response) => {
          this.locationTypes = response.data.data.data;
          this.locationmultiTypes = response.data.data.data.filter(item => item.id !== this.form.single);
          console.log(this.locationTypes,'location-type');
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getOptionLabel(option) {
      return option.title;
    },
    selectLocationType(selectedOptions) {
      this.form.selectedLocationTypeIds = selectedOptions.map(option => option.id);
      if (this.form.single && !this.form.selectedLocationTypeIds.includes(this.form.single)) {
      this.form.selectedLocationTypeIds.unshift(this.form.single);
    }   
    }
  },
  watch: {
    // Watch the entire `dishes` array for changes
    dishes: {
    handler(newDishes) {
      // Simply replace the old dishes array with the new one
      // this.dishes = newDishes.map((dish) => {
      //   return { ...dish }; // Spread the new dish properties into the new array
      // });
    },
    deep: true // Ensures changes inside array elements trigger the watcher
  }
  },
  created() {
    this.searchCategory = this.debounce(this.searchCategory, 500);
  }
};
</script>
<style src="vue-select/dist/vue-select.css" >
.image-container{
  background: red !important;
}
</style>