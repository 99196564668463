<script>
/**
* Stat component
*/
export default {
  props: ['newStats'],
  components: {
  },
  // props: ["newStats"],
  data() {
    let today = new Date().getDay();
    let barColors = new Array(7).fill("#d3d3d3");
    barColors[today === 0 ? 6 : today - 1] = "#EC421C";
    return {
      series: [
        {
          data: [],
        },
      ],
      chartSeries: [
        {
          name: "Activity",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 250,
          type: "bar",
          toolbar: {
            show: false,
          },
        },

        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center",
            },
            distributed: true,
          },

        },
        states: {
          hover: {
            filter: {
              type: "darken",
              value: 0.15,
            },
            colors: ["#EC421C"],
          },
        },
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val.toFixed(1);
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        colors: barColors,
        xaxis: {
          categories: [
            "Mon",
            "Tue",
            "Wed",
            "Thu",
            "Fri",
            "Sat",
            "Sun",
          ],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          labels: {
            show: true,
            formatter: function (val) {
              return Math.round(val);
            },
          },
          decimalsInFloat: 0,
          min: 0,
          max: 1000,
          tickAmount: 6,
        },
        title: {
          text: "",
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444",
          },
        },

      },
    }
  },
  watch: {
    newStats: {
      immediate: true,
      handler(newStats) {
        console.log("Received new stats:", this.newStats);

        if (newStats && newStats.users) {
          const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
          const weeklyData = daysOfWeek.map(day => newStats.users[day] || 0);
          this.chartSeries = [
            {
              name: "Activity",
              data: weeklyData,
            },
          ];
          const maxVal = Math.max(10);
          this.chartOptions.yaxis.max = Math.ceil(maxVal + 1);
        }
      },
    },
  },
}

</script>
<template>
  <div id="chart" class="customer">
    <apexchart type="bar" height="250" :options="chartOptions" :series="chartSeries"></apexchart>
  </div>
</template>
