<template>
  <Layout>
    <div class="addUser">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="card">
            <div class="card-body border border-radius-12">
              <PageHeader :title="title" :items="items" class="poppins fw-normal" />
              <form ref="form" @submit.prevent="updateLocationType">
                <!-- <div class="col-md-6">
                  <div class="mb-3">
                      <input
                        type="button"
                        id="image"
                        @click="openPopup"
                        v-model="buttonTypeVal"
                        placeholder="Enter Category Slug"
                        value="Select Image"
                      />
                      <div v-if="$v.form.icon.$error">
                        <div class="error" v-if="$v.form.icon.$error">
                          Icon is Required!
                        </div>
                      </div>
                    </div>
                    <input type="hidden" v-model.trim="$v.form.icon.$model" />
                  </div> -->
                  <section  class=" free_listing-banner d-flex align-items-center justify-content-center ms-2 my-3" >          
                    <div class="col-md-6 pointer"  @click="openPopup">
                    <div v-if="form.icon" class="text-center">
                    <img :src="form.icon" alt="Selected Icon" class="img-fluid" />
                     </div>
                     <input type="hidden" v-model="form.icon" />
                    </div>
                    </section>
                      <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Firstname-input"
                        >Location Type Title</label
                      >
                      <input
                        type="text"
                        class="form-control rounded-pill"
                        id="formrow-Firstname-input"
                        v-model="form.title"
                        placeholder="Enter Location Type"
                      />
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Lastname-input"
                        >Location Type Slug</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="formrow-Lastname-input"
                        v-model="form.slug"
                        placeholder="Enter Location Type slug"
                      />
                    </div>
                    <dir>
                      <img :src="form.icon" alt="location type Image">
                    </dir>
                  </div> -->
                  
                </div>
                <div class="d-flex flex-wrap gap-3 mt-3 border-orange p1 rounded-pill fit-content">
                  <button
                    type="submit"
                    class="btn btn-primary waves-effect waves-light w-md rounded-pill fw-bold px-5"
                  >
                    Update Location Type
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="category-modal" hide-footer title="Select Icon" size="xl">
      <p v-if="iconSelection">Please select at least one icon</p>
      <APILoader
        v-if="loading"
        :loading="loading"
        class="align-middle text-center"
      ></APILoader>
      <div
        v-else
        class="d-flex gap-3 text-center flex-wrap justify-content-center"
      >
        <div
          v-for="(img, index) in images"
          :key="index"
          @click="selectImg(index, img)"
          :class="[
            'd-flex',
            'flex-column',
            'justify-content-center',
            'gap-0',
            { 'img-category': selectedIndex === index },
            'cursor-pointer',
          ]"
        >
          <img
            :src="img.url"
            class="img-fluid mx-auto"
            alt="category-image"
            height="30px"
            width="30px"
          />
          <p class="mb-0 w-75 mx-auto">{{ img.name }}</p>
        </div>
      </div>
      <div
        class="d-flex justify-content-end border-orange fit-content rounded-pill p1 mt-4 cat-btn"
      >
        <b-button
          class="bg-orange rounded-pill text-white f-16 px-4 py-2"
          variant="outline-danger"
          block
          @click="hideModal"
          >Select Icon</b-button
        >
      </div>
    </b-modal>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import { required, alpha } from "vuelidate/lib/validators";
export default {
  name: "update Location Type",
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title:"Update Location Type",
      processing: false,
      buttonTypeVal: "Select an Location Type image",
      loading: true,
      images: [],
      iconSelection: false,
      form: {
        title: null,
        slug: null,
        icon: null,
      },
      selectedIndex: false,
    };
  },
  validations: {
    form: {
      title: {
        required,
        // alpha,
      },
      // slug: {
      //   required,
      //   alpha,
      // },
      icon: {
        required,
      },
    },
  },
  mounted() {
    this.singleLocationType();
  },
  methods: {
    selectImg(index, img) {
      this.iconSelection = false;
      this.selectedIndex = index;
      this.form.icon = img.url;
    },
    openPopup() {
      this.$refs["category-modal"].show();
      if (this.images.length < 1) {
        this.categoryImages();
      }
    },
    hideModal() {
      if (this.form.icon) {
        this.iconSelection = false;
        return this.$refs["category-modal"].hide();
      }
      this.iconSelection = true;
    },
    categoryImages() {
      this.$axios
        .get("location-category-images")
        .then((response) => {
          this.images = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    singleLocationType() {
      this.form.id =this.$route.params.id;
      this.$axios
        .get("location-type/" + this.form.id)
        .then((response) => {
          this.locationType = response.data;
          this.form.title = this.locationType.title;
          this.form.slug = this.locationType.slug;
          this.form.icon = this.locationType.icon;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    updateLocationType() {
      this.$axios
        .put("location-type/" + this.form.id, this.form)
        .then((response) => {
          this.triggerSwal(response.data.message, "success");
          this.$router.push({
            name: "location-type",
            query:{currentPage: this.$route.query.currentPage}
          });
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
          console.log(error);
        });
    },
  },
};
</script>
