<template>
  <Layout>
    
    <div class="addUser">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="card border">
            <div class="card-body">
              <PageHeader :title="title" :items="items" class="poppins fw-normal" />
              <form ref="form" @submit.prevent="updateCategory">
                <section  class=" free_listing-banner d-flex align-items-center justify-content-center ms-2 my-3" >          
                      <div class="col-md-6 pointer"  @click="openPopup">
                      <div v-if="form.icon" class="text-center">
                      <img :src="avatar_icon" alt="Selected Icon" class="img-fluid" />
                       </div>
                       <input type="hidden" v-model="form.icon" />
                      </div>
                      </section>
                <div class="row">
                  <div class="col-md-8">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Firstname-input"
                        >Category Title</label
                      >
                      <input
                        type="text"
                        class="form-control rounded-pill"
                        id="formrow-Firstname-input"
                        v-model="form.title"
                        placeholder="Enter Category Name"
                      />
                    </div>
                  </div>
                  <!-- <div class="col-md-8">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Lastname-input"
                        >Category Slug</label
                      >
                      <input
                        type="text"
                        class="form-control rounded-pill"
                        id="formrow-Lastname-input"
                        v-model="form.slug"
                        placeholder="Enter category slug"
                      />
                    </div>
                  </div> -->
                </div>
                <div class="d-flex flex-wrap  gap-3 mt-3 border-orange rounded-pill p1 fit-content">
                  <button
                    type="submit"
                    class="btn btn-primary waves-effect waves-light w-md rounded-pill fw-bold px-5"
                  >
                    Update Poll Category
                  </button>
                </div>
              </form>
              <b-modal ref="category-modal" hide-footer title="Select Icon" size="xl">
        <p v-if="iconSelection">Please select at least one icon</p>
        <APILoader v-if="loading" :loading="loading" class="align-middle text-center"></APILoader>
        <div v-else class="row text-center">
          <div v-for="(img, index) in images" :key="index" @click="selectImg(index, img)"
            :class="['d-flex', 'flex-column', 'justify-content-center', 'gap-0','col-2', { 'img-category': selectedIndex === index }, 'cursor-pointer']">
            <img :src="img.url" class="img-fluid mx-auto" alt="category-image" height="30px" width="30px">
            <p class="mb-0 w-75 mx-auto">{{ img.name }}</p>
          </div>
        </div>
        <div class="d-flex justify-content-end border-orange fit-content rounded-pill p1 mt-4 cat-btn">
          <b-button class="bg-orange rounded-pill text-white f-16 px-4 py-2" variant="outline-danger" block
            @click="hideModal">Select
            Icon</b-button>
        </div>
      </b-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
export default {
  name: "update-category",
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Update Poll Category",
      images: [],
        iconSelection: false,
        items:[],
        avatar_icon: [],
      form: {
        id: this.$route.params.id,
        title: null,
        slug: null,
        icon: [],
        
      },
    };
  },
  mounted() {
    this.singleCategory();
  },
  methods: {
    selectImg(index, img) {
        this.iconSelection = false;
        this.selectedIndex = index;
        this.form.icon = img.url;
        this.avatar_icon = img.url;
      },
      openPopup() {
        this.$refs['category-modal'].show();
        if (this.images.length < 2) {
          this.categoryImages();
        }
      },
      hideModal() {
        if (this.avatar_icon) {
          this.iconSelection = false;
          return this.$refs['category-modal'].hide();
        }
        this.iconSelection = true;
      },
      categoryImages() {
        this.$axios.get('category-images')
          .then((response) => {
            this.images = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
          });
      },
    singleCategory() {
      this.$axios
        .get("categories/" + this.form.id)
        .then((response) => {
          this.form.title = response.data.title;
          this.form.slug = response.data.slug;
          this.form.icon = response.data.icon;
          this.avatar_icon = response.data.avatar_icon;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    updateCategory() {
      this.$axios
        .put("categories/" + this.form.id, this.form)
        .then((response) => {
          this.$router.push({
            name: "category",
            query:{currentPage: this.$route.query.currentPage}
          });
          this.triggerSwal(response.data.message, "success");
          console.log(response.data);
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
          console.log(error.response);
        });
    },
  },
};
</script>