<template>
  <Layout>
   

    <div class="row">
      <div class="col-lg-12">
        <div class="card border border-radius-12">
          <div class="card-body table">
            <div  class="row">
            <div class="col-xl-8 col-12"> 

              <div class="d-flex align-items-sm-center align-items-start gap-lg-3 gap-2 flex-column flex-sm-row"> 
                <PageHeader title="Location Type Listing" class="poppins fw-normal text-nowrap ps-3" />
             
                <searchInput @getSearched="handleSearch" class="w-100"/>
                <pageSize @getPageSize="handlePageSize" class="w-100"/>
            
              </div>
              </div>
             <!-- <div :class="{
               'del_btn-wrapper': true,
                'border-orange rounded-pill p1': multipuleIds.length >= 1
                    }">
                <button
                  v-if="multipuleIds.length >= 1"
                  @click="deleteBulk"
                  class="btn border-0 px-5 fw-bold text-white btn btn-primary waves-effect waves-light w-md primary-button rounded-pill"
                >
                  Delete All
                </button>
              </div> -->
              <div class="col-xl-4 col-12"> 
                <div class="d-flex justify-content-end">
              <div class="add_new border-orange rounded-pill p1">
                <router-link
                  to="/location-type/add"
                  class="btn btn-primary border-0 px-5 fw-bold text-white btn btn-primary waves-effect waves-light w-md primary-button rounded-pill"
                >
                  Add New
                </router-link>
              </div>
              </div>
            </div>
            </div>
          
            <!-- end table-responsive -->
            <GeneralTable 
            :isProcessing="processing"
            :items="locationTypes" :fields="fields" 
            @editItem="locationTypeUpdate"
            @deleteItem="deleteUser"
            :editUrl="'update-location-type'"
        ></GeneralTable>
        <Pagination
          @paginator="allLocationType"
          :pagination="paginatelinks"
          :showing="paginate"
          @page-changed="handlePageChange"
        />
         
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import Swal from "sweetalert2";
import GeneralTable from "../../../../components/MagicTable/GeneralTable.vue";
import searchInput from "../../../../components/general/searchInput.vue";
import Pagination from "../../../../components/general/pagination.vue";
import pageSize from "../../../../components/general/pageSize.vue";

export default {
  name: "sub-category",
  components: {
    Layout,
    PageHeader,
    GeneralTable,
    searchInput,
    pageSize,
    Pagination,
  },
  data() {
    return {
      currentPageNumber: null,
      fields: [
        { key: 'select', label: 'Select', sortable: false, },  
        { key: 'id'},
        { key: 'avatar_with_title', label: 'Location Type', },
        { key: 'created_at',tdClass:'align-middle'},
        { key: 'updated_at', label: 'Last Updated',tdClass:'align-middle'},
        { key: 'action',tdClass:'align-middle'},
      ],
      multipuleIds: [],
      selectAll: false,
      title: "Sub Category Listing",
      loading: true,
      locationTypes: null,
      processing: false,
      paginate: {},
      paginatelinks: [],
      pageSize: 10,

    };
  },
  mounted() {
    let url = null;
    if (this.$route.query.currentPage) {      
      url = process.env.VUE_APP_BASEURL + '/location-type?page=' + this.$route.query.currentPage;
    }
    this.allLocationType({ url });
    this.processing = true;
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.items.slice(start, start + this.itemsPerPage);
    }
  },
  methods: {
    handlePageChange(pageNumber) {
      if (pageNumber <= 0) {
          this.triggerSwal("Page not found. Please enter a valid page number.", "error");
        return;
      }

      const lastPageUrl = this.paginate?.last_page;
      let lastPage = 1;
      if (lastPageUrl) {
        const match = lastPageUrl.match(/page=(\d+)/);
        if (match && match[1]) {
          lastPage = parseInt(match[1], 10);
        }
      }

      if (pageNumber > lastPage) {
        this.triggerSwal("Page not found. Please enter a valid page number.", "error");
        return;
      }
      const baseUrl = "location-type"; 
      const pageUrl = `${baseUrl}?page=${pageNumber}`;
      this.allLocationType({ url: pageUrl });
    },
    deleteUser(id) {
      let apiUrl = `location-type/`;
           let confirmationMessage = "Location deleted successfully."
           this.processing = true;
           this.deleteIt(apiUrl, id,confirmationMessage)
           .then(() => {
            this.processing = false;
            this.allLocationType({});
            }).catch(error => {
                console.error('Error deleting job:', error);
                this.processing = false;
            });
      console.log('Received ID from child:', id);
    },
     formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString(); 
},
handlePageSize(newPageSize) {
      this.pageSize = newPageSize || 10;
      this.allLocationType({ pageSize: this.pageSize });
    },
    randerData(response) {
      this.currentPageNumber = response.data.data.current_page;
     this.paginate = {
      first_page: response?.data?.data?.first_page_url,
      last_page: response?.data?.data?.last_page_url,
              links: response?.data?.data?.links,
              from: response?.data?.data?.from,
              to: response?.data?.data?.to,
              total: response?.data?.data?.total,
     }
     this.paginate.links = this.paginate?.links?.length > 0 ? this.paginate.links:[]
     this.paginatelinks=this.paginate.links.map(item=>({
              url: item.url,
              label: item.label,
              active: item.active,
            }));

        //     let locationTypes = Array.isArray(response.data.data.data) && response.data.data?.data?.length > 0
        // ? response.data.data.data
        // : [];
       
        // console.log(locationTypes,"data");
  let locationTypes = Array.isArray(response?.data?.data?.data) && response?.data?.data?.data?.length > 0 
    ? response.data.data.data
    : [];
  this.locationTypes = locationTypes.map(location => ({
    id: location.id,
    avatar: location.icon,
    name: location.title,
    slug: location.slug,
    created_at: this.formatDate(location.created_at), 
  updated_at: this.formatDate(location.updated_at),
  }));
  this.processing = false;
  console.log(this.locationTypes, "formatted locationTypes");

      // if ($.fn.dataTable.isDataTable("#datatable")) {
      //   let table = $("#datatable").DataTable();
      //   table.destroy();
      // }
      // setTimeout(() => {
      //   $("#datatable").DataTable({
      //     ordering: false,
      //     lengthMenu: [
      //       [5, 10, 25, 50, -1],
      //       [5, 10, 25, 50, "All"],
      //     ],
      //     pageLength: 25,
      //   });
      // });
    },
    allLocationType({url = null, searchItem = null,  pageSize = this.pageSize } = {}) {
      let endPoint = "location-type";
      endPoint = url ? url : endPoint;
      this.$axios
      .get(endPoint, {params:{search: searchItem, page_size: pageSize}})
        .then((response) => {
          setInterval(this.randerData(response), 1500);
          console.log(response.data.data, "then");
        })
        .catch((error) => {
          if(error.response == undefined) {
            this.locationTypes = [];
          }
          console.log(error.response,"error");
          this.processing = false;
        });
    },
    async delete(id) {
      try {
        const {
          data: { status },
        } = await this.$axios.delete("location-type/" + id);
        this.responseDelete = status;
      } catch (error) {
        console.log(error);
      }
    },
    deleteLocationType(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.delete(id);
          let status = this.responseDelete;
          if (status == true) {
            Swal.fire("Deleted!", "Record has been deleted.", "success");
            this.allLocationType({});
          }
        }
      });
    },
    locationTypeUpdate: function (id) {
      this.$router.push({
        name: "update-location-type",
        params: { id: id },
        query: { currentPage: this.currentPageNumber },
      });
    },
    handleSearch(searchItem) {
       this.allLocationType({searchItem});
  },
  
    selectAllRecord() {
      if (this.selectAll) {
        let ids = [];
        this.locationTypes.forEach((element) => {
          ids.push(element.id);
        });
        this.multipuleIds = [...new Set(ids)];
        console.log(this.multipuleIds);
      } else {
        this.multipuleIds = [];

        console.log(this.multipuleIds);
      }
    },
    deleteBulk() {
      this.$axios
        .post("location-type/bulk/delete/records", { ids: this.multipuleIds })
        .then((response) => {
          this.allLocationType({});
          this.triggerSwal(response.data.message, "success");
          this.selectAll = false;
          this.multipuleIds = [];
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>
