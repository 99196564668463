<template>
  <div>
    <date-picker
      v-model="date"
      :disabled-date="disabledAfterToday"
      :disabled="disabledComponent"
      :multiple="multiple"
      format="MMMM YYYY"
      :input-class="className"
      placeholder="Month Year" 
      :style="{ width: '100%' }"
      type="month"
      value-type="format"
    >
      <template v-slot:icon-calendar>
        <i class="fal fa-calendar me-1 ms-1"></i>
      </template>
    </date-picker>
    <slot name="icon"></slot>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "MonthYearPicker",

  components: {
    DatePicker,
  },

  props: {
    value: {
      type: [String, Number],
    },
    className: {
      type: String,
      default: "form-control rounded-pill px-2 border-2",
    },
    disabledComponent: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxSelectOne: {
      type: Boolean,
    },
  },

  data() {
    return {
      date: null,
    };
  },

  watch: {
    value(newValue) {
      if (this.isMounted && newValue) {
        this.date = newValue.toString();
      }
    },
    date(newDate) {
      // Emit selected date to parent
      this.$emit("input", newDate);
    },
  },

  mounted() {
    if (!this.date) {
      this.date = this.getDefaultDate();  
      this.$emit("input", this.date);
    }
  },

  methods: {
    getDefaultDate() {
      const today = new Date();
      return today.toLocaleString("en-US", { month: "long", year: "numeric" });
    },

    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (this.multiple && this.maxSelectOne && this.date) {
        const selectedYear = new Date(this.date).getFullYear();
        return date > selectedYear || date < selectedYear;
      }

      return date > today;
    },
  },
};
</script>

<style>
/* Add your custom styles here if needed */
</style>
