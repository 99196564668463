<template>
  <div id="chart">
    <apexchart type="area" height="250" :options="chartOptions" :series="series">
    </apexchart>
  </div>
</template>

<script>

export default {
  props: ['newStats'],
  name: "MyChart",
  components: {
  },
  data() {
    return {
      series: [
        {
          name: 'Resturant',
          data: [] // Single series data
        }
      ],
      chartOptions: {
        chart: {
          type: 'area',
          height: 250,
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          colors: ['#EC421C'],
        },
        fill: {
          colors: ['#EC421C'],
          opacity: 0.2,
        },
        xaxis: {
          categories: [],
        }, 
      }
    };
  },
  watch: {
    newStats: {
      immediate: true,
      handler(newStats) {
        if (newStats && newStats.businesses && newStats.businesses.Daily_Restaurants) {
          const dailyRestaurants = newStats.businesses.Daily_Restaurants;

          const days = Object.keys(dailyRestaurants);
          const counts = Object.values(dailyRestaurants); 

          this.chartOptions.xaxis.categories = days.map(day => `Day ${day}`);
          this.series[0].data = counts;
        }
      }
    }
  }
};
</script>