<script>
/**
 * Top-users component
 */
import paginator from "@/components/paginator";
export default {
  props: ["businesses"],
  components: {
    paginator,
  },
  data() {
    return {
      paginate:null,
      paginatelinks:null,
    };
  },
  mounted() { },
  watch:{
    businesses(newVal){
      this.getResturantPasginate();
    }
  },
  methods: {
    handleImageError(event) {
      event.target.src = require('@/assets/images/placeholder-2.png');
    },
    getSafe(fn, defaultValue = "N/A") {
      try {
        return fn();
      } catch (error) {
        return defaultValue;
      }
    },

    getResturantPasginate()
    {
      this.paginate = {
          first_page: this.businesses.first_page_url|| [],
          last_page: this.businesses.last_page_url || [],
          links: this.businesses.links || []
        };
        this.paginatelinks = this.paginate.links.map(item => ({
          url: item.url,
          label: item.label,
          active: item.active
        }));
    },
    emitUrl({url})
    {      
      this.$emit('paginationUrl', url);
    },
  },
};
</script>

<template>
  <div class="col-lg-6 col-12 mb-3">
    <div class="card border border-radius-12 shadow-none h-100">
      <div class="card-body">
        <div class="float-end">
          <router-link to="business/listing " class="gray-text fw-600">View All</router-link>
        </div>
        <!-- <div class="float-end">
          <b-dropdown
            variant="white"
            toggle-class="p-0"
            menu-class="dropdown-menu-end"
          >
            <template v-slot:button-content>
              <span class="text-muted">
                All Members
                <i class="mdi mdi-chevron-down ms-1"></i>
              </span>
            </template>
<b-dropdown-item href="#">Locations</b-dropdown-item>
<b-dropdown-item href="#">Revenue</b-dropdown-item>
<b-dropdown-item href="#">Join Date</b-dropdown-item>
</b-dropdown>
</div> -->
        <h4 class="card-title mb-4">Restaurants</h4>
        <div data-simplebar style="max-height: 336px">
          <div class="table-responsive">
            <table class="table table-borderless table-centered table-nowrap gray-text ">
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Restaurant Email</th>
                <th>Rating</th>
                <th>Ranking</th>
                <th>Price Range</th>
                <th>Contact No</th>
                <th>Website</th>
                <th>Created At</th>
              </tr>
              <tbody>
                <tr v-if="!businesses">
                  <td class="text-center" colspan="9">Record Not Found</td>
                </tr>
                <tr v-else v-for="(item, index) in businesses.data" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td><span> <img class="dashboard-avatar-resturant" 
                    :src="item.banner_url ? item.banner_url : require('@/assets/images/placeholder-2.png')" alt=""
                    @error="handleImageError">
                  </span> {{ getSafe(() => item.title, "N/A") }}</td>
                  <td>{{ getSafe(() => item.email, "N/A") }}</td>
                  <td>{{ getSafe(() => item.rating, "N/A") }}</td>
                  <td>{{ getSafe(() => item.ranking, "N/A") }}</td>
                  <td>{{ getSafe(() => item.price_range, "N/A") }}</td>
                  <td>{{ getSafe(() => item.mobile, "N/A") }}</td>
                  <td>{{ getSafe(() => item.website, "N/A") }}</td>
                  <td>{{ getSafe(() => item.created_at, "N/A") }}</td>
                </tr>
              </tbody>
            </table>
          </div>
         
          <!-- enbd table-responsive-->
        </div>
        <div class="dataTables_paginate paging_simple_numbers float-end pt-3">
            <ul class="pagination pagination-rounded mb-0">
              <paginator :pagination="paginatelinks" @paginator="emitUrl"/>
            </ul>
          </div>
        <!-- data-sidebar-->
      </div>
      <!-- end card-body-->
    </div>
    <!-- end card-->
  </div>
  <!-- end col -->
</template>
