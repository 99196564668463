<template>
  <Layout>
    <PageHeader :title="title" class="poppins fw-normal" />
    <div class="addUser">
      <div class="row">
        <div class="col-lg-12 col-12">
          <div class="card">
            <div class="card-body d-flex flex-wrap justify-conten-between align-items-start py-5 gap-3">
              <div class="card-body-inner custom-csv">

                <vue-csv-import :inputClass="'csv-import-file fit-content mt-2 rounded-pill p-0'"
                  :autoMatchFields="true" :canIgnore="true" :headers="false" :autoMatchIgnoreCase="true"
                  :fileMimeTypes="csvFileType" v-model="parseCsv" :map-fields="[
                  'resturant_name',
                  'phone_number',
                  'website_url',
                  'street_address',
                  'logo',
                  'resturant_description',
                  'manu_link',
                  'price_range',
                  'facebook_url',
                  'instagram_url',
                  'twitter_url',
                  'poll_category_1',
                  'poll_category_rank_1',
                  'poll_category_2',
                  'poll_category_rank_2',
                  'poll_category_3',
                  'poll_category_rank_3',
                  'poll_category_4',
                  'poll_category_rank_4',
                  'poll_category_5',
                  'poll_category_rank_5',
                  'poll_category_6',
                  'poll_category_rank_6',
                  'poll_category_7',
                  'poll_category_rank_7',
                  'poll_category_8',
                  'poll_category_rank_8',
                  'poll_category_9',
                  'poll_category_rank_9',
                  'poll_category_10',
                  'poll_category_rank_10',
                  'location_category',
                  'location_category_rank',
                  'image_gallery',
                  'location_type',
                  'restaurant_hours',
                ]">
                  <template slot="error"> File type is invalid </template>
                  <template slot="next" slot-scope="{ load }">
                    <button @click.prevent="load" class="
                      btn
                      border-0
                      px-5
                      fw-bold
                      text-white
                      btn btn-primary
                      waves-effect waves-light
                      rounded-pill
                      primary-button
                    ">
                      Import File Data
                    </button>
                  </template>

                  <template slot="submit" slot-scope="{ submit }">
                    <button @click.prevent="submit" class="
                      btn
                      border-0
                      px-5
                      fw-bold
                      text-white
                      btn btn-primary
                      waves-effect waves-light
                      w-md
                      primary-button
                      rounded-pill
                    ">
                      Submit!
                    </button>

                    <vue-csv-map v-if="this.parseCsv != null"></vue-csv-map>
                  </template>
                </vue-csv-import>
                <div class="col-12 col-lg-4 text-center csv_sample_download"><a
                    class="btn btn py-2 text-decoration-underline" :href="'/files/Example.csv'" download><img
                      src="@/assets/images/Upload.svg" class="me-2"> Download Sample CSV File</a></div>
                <div class="text-center">
                  <button v-if="this.parseCsv != null" @click="checkData" class="
                    btn
                    border-0
                    mb-3
                    px-5
                    fw-bold
                    text-white
                    btn btn-primary
                    waves-effect waves-light
                    w-md
                    primary-button
                    rounded-pill
                  ">
                    Submit
                  </button>
                </div>

                <div class="container">
                  <div class="table-responsive">
                    <table v-if="this.parseCsv != null" class="table">
                      <thead>
                        <tr class="text-nowrap">
                          <th scope="col">Business Name</th>
                          <th scope="col">Business Mobile Phone</th>
                          <th scope="col">Website URL</th>
                          <th scope="col">street Address</th>
                          <th scope="col">Logo Url</th>
                          <th scope="col">Business Description</th>
                          <th scope="col">Manu Link</th>
                          <th scope="col">Price Range</th>
                          <th scope="col">Facebook Url</th>
                          <th scope="col">Instagram Url</th>
                          <th scope="col">Twitter Url</th>
                          <th scope="col">Poll Category 1</th>
                          <th scope="col">Poll Category Rank 1</th>
                          <th scope="col">Poll Category 2</th>
                          <th scope="col">Poll Category Rank 2</th>
                          <th scope="col">Poll Category 3</th>
                          <th scope="col">Poll Category Rank 3</th>
                          <th scope="col">Poll Category 4</th>
                          <th scope="col">Poll Category Rank 4</th>
                          <th scope="col">Poll Category 5</th>
                          <th scope="col">Poll Category Rank 5</th>
                          <th scope="col">Poll Category 6</th>
                          <th scope="col">Poll Category Rank 6</th>
                          <th scope="col">Poll Category 7</th>
                          <th scope="col">Poll Category Rank 7</th>
                          <th scope="col">Poll Category 8</th>
                          <th scope="col">Poll Category Rank 8</th>
                          <th scope="col">Poll Category 9</th>
                          <th scope="col">Poll Category Rank 9</th>
                          <th scope="col">Poll Category 10</th>
                          <th scope="col">Poll Category Rank 10</th>
                          
                          <th scope="col">location Category	</th>
                          <th scope="col">location Category Rank	</th>
                          <th scope="col">Image Gallery</th>
                          <th scope="col">Location Type</th>
                          <th scope="col">Restaurant Hours</th>


                          <th scope="col">Remove Row</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(csvData, index) in newRecord" :key="index">
                          <td>{{ csvData.resturant_name }}</td>
                          <td>{{ csvData.phone_number }}</td>
                          <td>{{ csvData.website_url }}</td>
                          <td>{{ csvData.street_address }}</td>
                          <td>{{ csvData.logo }}</td>
                          <td>{{ csvData.resturant_description }}</td>
                          <td>{{ csvData.manu_link }}</td>
                          <td>{{ csvData.price_range }}</td>
                          <td>{{ csvData.facebook_url }}</td>
                          <td>{{ csvData.instagram_url }}</td>
                          <td>{{ csvData.twitter_url }}</td>
                          <td>{{ csvData.poll_category_1 }}</td>
                          <td>{{ csvData.poll_category_rank_1 }}</td>
                          <td>{{ csvData.poll_category_2 }}</td>
                          <td>{{ csvData.poll_category_rank_2 }}</td>
                          <td>{{ csvData.poll_category_3 }}</td>
                          <td>{{ csvData.poll_category_rank_3 }}</td>
                          <td>{{ csvData.poll_category_4 }}</td>
                          <td>{{ csvData.poll_category_rank_4 }}</td>
                          <td>{{ csvData.poll_category_5 }}</td>
                          <td>{{ csvData.poll_category_rank_5 }}</td>
                          <td>{{ csvData.poll_category_6 }}</td>
                          <td>{{ csvData.poll_category_rank_6 }}</td>
                          <td>{{ csvData.poll_category_7 }}</td>
                          <td>{{ csvData.poll_category_rank_7 }}</td>
                          <td>{{ csvData.poll_category_8 }}</td>
                          <td>{{ csvData.poll_category_rank_8 }}</td>
                          <td>{{ csvData.poll_category_9 }}</td>
                          <td>{{ csvData.poll_category_rank_9 }}</td>
                          <td>{{ csvData.poll_category_10 }}</td>
                          <td>{{ csvData.poll_category_rank_10 }}</td>
                         
                          <td>{{ csvData.location_category }}</td>
                          <td>{{ csvData.location_category_rank }}</td>
                          <td>{{ csvData.image_gallery }}</td>
                          <td>{{ csvData.location_type }}</td>
                          <td>{{ csvData.restaurant_hours ? csvData.restaurant_hours : 'Closed' }}</td>
                          <td>
                            <button @click="deleteRow(index)">Remove</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import { VueCsvImport } from "vue-csv-import";
export default {
  components: {
    VueCsvImport,
    Layout,
    PageHeader,
  },
  data() {
    return {
      categoryOptions:null,
      csvFileType: [
        "text/csv",
        "text/x-csv",
        "application/vnd.ms-excel",
        "text/plain",
      ],
      parseCsv: null,
      newRecord: null,
      title: "Upload Bulk Business",
    };
  },
  watch: {
    parseCsv(newValue, oldValue) {
      // console.log(newValue);
      return (this.newRecord = newValue.slice(1));
    },
  },
  mounted(){
    this.allCategories();
  },
  methods: {
    deleteRow(index) {
      this.parseCsv.splice(index, 1);
    },
    checkData() {
      // console.log(this.newRecord);
      this.$axios
        .post("upload-file-content", this.newRecord)
        .then((response) => {
          
          this.triggerSwal(response.data.message, "success");
          this.newRecord = null;
          this.parseCsv = null;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>
<style>

/* select.form-control:not([multiple=multiple]):focus {
  background-image: url('~@/assets/images/Chevron Up.png');
}
select.form-control:not([multiple=multiple]):not(:focus) {
  background-image: url('~@/assets/images/chevron down.png');
} */
</style>