<template>
    <div class="search-box">    
        <div class="dropdown-wrapper position-relative">
  <BFormSelect
    :class="['form-control', 'rounded-pill', customClass, 'dropdown-select']"
    id="input-3"
    v-model="pageSize"
    :options="records"
    @change="handlePageSize"
  />
  <span class="dropdown-chevron position-absolute">
    <i class="fas fa-chevron-down"></i>
  </span>
    </div>
    </div>
</template>
<script>


export default {
    data() {
        return {
            pageSize: null,
            records: [{ text: 'Page Size', value: null }, '10', '25', '50']
        }
    },
    props: {
        customClass:{
          type: String,
          default: ''
        }

    },
    mounted() {

    },
    methods: {
        handlePageSize()
        {
            this.$emit('getPageSize', this.pageSize);
        }
    }
}



</script>
<style scoped>
.custom-width{
    width: 27%;
}
.dropdown-wrapper {
  position: relative;
  display: inline-block;
  width: 100%; 
}

.dropdown-select {
  appearance: none;
  padding-right: 2.5rem; 
}

.dropdown-chevron {
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  pointer-events: none; 
  color: #60676d;
  font-size: 12px;
}
</style>