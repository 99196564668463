<template>
    <div>
        <div class="d-flex justify-content-between align-items-center flex-column flex-md-row pt-3">
        <template  v-if="pagination.length">
            <div class="custom_pagination order-2">
                <template v-for="(link, index) in displayedPagination" >
                    <template v-if="link.isChevron">
                        <span :key="index" class="custom-pagination-links" @click="$emit('paginator', {url: link.url})">
                            <i :class="link.iconClass"></i>
                        </span>
                    </template>
                    <template v-else>
                        <span :key="index" class="custom-pagination-links" :class="{'active-paginate': link.active}" @click="link.url && $emit('paginator', {url: link.url})">
                            {{ link.label }}
                        </span>
                    </template>
                </template>
            </div>
         </template>
       <div>
           <p class="order-1 mb-0">Showing {{ showing.from }} to {{ showing.to }} of {{ showing.total }} results</p>
       </div>
       <div class="d-flex justify-content-end">
        <goToPage @page-changed="$emit('page-changed', $event)" />
    </div>
    </div>
    
  </div>
</template>

<script>
import goToPage from './gotoPage.vue'
export default {
    components:{
        goToPage
    },
    props: {
        pagination: {
            type: Array,
            default() {
                return [];
            }
        },
        showing: {
      type: Object,
      required: true
    }
    },
    computed: {
        displayedPagination() {
            const length = this.pagination.length;
            const activeIndex = this.pagination.findIndex(link => link.active);

            if (length > 5) {
                const pages = [
                    this.pagination[1],  
                    this.pagination[2], 
                ];

                if (activeIndex > 2 && activeIndex < length - 2) {
                    pages.push({ label: '...', url: null });
                    pages.push(this.pagination[activeIndex]);
                    pages.push({ label: '...', url: null });
                } else {
                    pages.push({ label: '...', url: null });
                }

                pages.push(this.pagination[length - 2]);  
                

                return [
                    { isChevron: true, iconClass: 'fas fa-chevron-left', url: this.pagination[0].url },
                    ...pages,
                    { isChevron: true, iconClass: 'fas fa-chevron-right', url: this.pagination[length - 1].url }
                ];
            } else {
                const pages = this.pagination.slice(1, length - 1);
                return [
                    { isChevron: true, iconClass: 'fas fa-chevron-left', url: this.pagination[0].url },
                    ...pages,
                    { isChevron: true, iconClass: 'fas fa-chevron-right', url: this.pagination[length - 1].url }
                ];
            }
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.$forceUpdate(); 
        }
    }
};
</script>
<style scoped>
.custom-pagination-links {
    background: #e0dfdf;
    color: #000;
}
.active-paginate {
    background: #ec421c !important;
    color: #fff !important;
}
</style>