<template>
  <Layout>


    <div class="row">
      <div class="col-lg-12">
        <div class="card border border-radius-12">

          <div class="card-body table mb-0">
            <div class="row justify-content-between">

              <div class="col-xl-8 col-12">
                <div class="d-flex align-items-sm-center align-items-start gap-lg-3 gap-2 flex-column flex-sm-row">
                  <PageHeader :title="title" :items="items" class="poppins fw-normal ps-3 text-nowrap" />
                  <searchInput @getSearched="handleSearch" class="w-100" />
                  <pageSize @getPageSize="handlePageSize" class="w-100" />
                  <div class="w-100">
                  <select @change="filtration" v-model="sort_by"
                    class="form-select sm-select rounded-pill fs-16 sort-filter px-3" aria-label="Sort By">
                    <option value="" selected disabled>Sort By</option>
                    <option value="alphabetically">User Name (A-Z)</option>
                    <option value="created_on">Registered</option>
                    <option value="user_id">Id</option>
                  </select>
                </div>
                </div>

              </div>

              <!-- <div class="del_btn-wrapper">

                                    <button v-if="multipuleIds.length >= 1" @click="deleteBulk" class="
                        btn
                        border-0
                        px-5
                        fw-bold
                        text-white
                        btn btn-primary
                        waves-effect waves-light
                        w-md
                        primary-button
                        rounded-pill
                      ">
                  Delete All
                </button>
              </div> -->
              <div class="col-xl-4 col-12">
                <div class="d-flex justify-content-end">

                  <div class="add_new border-orange rounded-pill p1"> <router-link to="/users/add" class="
                  btn btn-primary border-0 px-5 fw-bold text-white btn btn-primary waves-effect waves-light w-md primary-button rounded-pill
               ">
                      Add New
                    </router-link></div>
                </div>

              </div>
            </div>
            <GeneralTable :isProcessing="processing" :items="items" :fields="fields" @deleteItem="deleteUser"
              @editItem="userUpdate"
              :editUrl ="'update-user'">
            </GeneralTable>
            <Pagination @paginator="allUsers" :pagination="paginatelinks" :showing="paginate"
            @page-changed="handlePageChange" />
            <!-- end table-responsive -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import $ from "jquery";
import GeneralTable from "../../../../components/MagicTable/GeneralTable.vue";
import Pagination from "../../../../components/general/pagination.vue";
import searchInput from "../../../../components/general/searchInput.vue";
import pageSize from "../../../../components/general/pageSize.vue";

export default {
  page: {
    title: "User Listing",
    meta: [
      {
        name: "add-user",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    GeneralTable,
    Pagination,
    searchInput,
    pageSize,
  },

  data() {
    return {
      currentPageNumber: null,
      fields: [
        { key: 'select', label: 'Select', sortable: false },  
        { key: 'id'},
        { key: 'avatar_with_title', label: 'User Name'},
        { key: 'email',tdClass:'align-middle'},
        { key: 'mobile', label: 'Phone',tdClass:'align-middle'},
        { key: 't_shirt_size',tdClass:'align-middle'},
        { key: 'registeredon', label: 'Registered On',tdClass:'align-middle'},
        { key: 'action',tdClass:'align-middle'},
      ],
      sort_by: '',
      selectedRows: [], 
      items: [],
      currentPage: 1,
      itemsPerPage: 10,
      multipuleIds: [],
      selectAll: false,
      loading: true,
      users: null,
      paginatelinks: [],
      title: "User Listing",
      processing: false,
      paginate: {},
      pageSize: 10,
    };
  },

  mounted() {
    let url = null;
    if (this.$route.query.currentPage) {      
      url = process.env.VUE_APP_BASEURL + '/all-users?page=' + this.$route.query.currentPage;
    }
    this.allUsers({url});
    this.processing = true;
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.items.slice(start, start + this.itemsPerPage);
    }
  }, 
  methods: {
    handlePageChange(pageNumber) {
      if (pageNumber <= 0) {
          this.triggerSwal("Page not found. Please enter a valid page number.", "error");
        return;
      }

      const lastPageUrl = this.paginate?.last_page;
      console.log(pageNumber);
      let lastPage = 1;
      if (lastPageUrl) {
        const match = lastPageUrl.match(/page=(\d+)/);
        if (match && match[1]) {
          lastPage = parseInt(match[1], 10);
        }
      }

      if (pageNumber > lastPage) {
        this.triggerSwal("Page not found. Please enter a valid page number.", "error");
        return;
      }
      const baseUrl = "all-users"; 
      const pageUrl = `${baseUrl}?page=${pageNumber}`;
      this.allUsers({ url: pageUrl });
    },
    deleteUser(id) {
      let apiUrl = `delete-user/`;
           let confirmationMessage = "User deleted successfully."
           this.processing = true;
           this.deleteIt(apiUrl, id,confirmationMessage)
           .then(() => {
            this.processing = false;
            this.allUsers({});
            }).catch(error => {
                console.error('Error deleting job:', error);
                this.processing = false;
            });
      console.log('Received ID from child:', id);
    },
    handleSearch(searchItem) {
       this.allUsers({searchItem});
     // this.allUsers({ url: `search-users?query=${searchTerm}` }); 
  },
  filtration() {
  this.allUsers({ sortBy: this.sort_by });
},
    updateCurrentPage(newPage) {
      this.currentPage = newPage; 
    },
    handlePageSize(newPageSize) {
      this.pageSize = newPageSize || 10;
      this.allUsers({ pageSize: this.pageSize });
    },
    randerData(response) { 
      this.currentPageNumber = response.data.data.current_page;
       this.paginate={
              first_page: response.data.data.first_page_url,
              last_page: response.data.data.last_page_url,
              links: response.data.data.links,
              from: response.data.data.from,
              to: response.data.data.to,
              total: response.data.data.total,
            };
     
            this.paginatelinks=this.paginate.links.map(item=>({
              url: item.url,
              label: item.label,
              active: item.active,
            }));
      let users = Array.isArray(response.data.data.data) && response.data.data.data.length > 0 
  ? response.data.data.data 
  : [];   
      this.items = users.map(user => ({
      id: user.id,
      avatar:user.avatar,
      name: user.full_name,
      email: user.email,
      mobile: user.mobile,
      t_shirt_size: user.t_shirt_size,
      registeredon: user.created_at_formatted
    }));
    this.processing = false;
    },

    allUsers({url= null, searchItem=null,sortBy=null, pageSize = this.pageSize } = {}) {
      this.processing = true;
      let endPoint = "all-users";
      endPoint = url ? url : endPoint;
      this.$axios
        .get(endPoint, {params:{
          search: searchItem,
          sort_by: sortBy,
          page_size: pageSize 
        }})
        .then((response) => {
          console.log('User Data:', response.data);
          setTimeout(() => this.randerData(response), 1500);
        })
        .catch((error) => {
          console.log(error.response);
           this.processing = false;
        });
    },
   
    updateRecords(records) {
      this.users = records;
      this.links = records;
    },
    // Record update Function
    userUpdate: function (id) {
      this.$router.push({
        name: "update-user",
        params: { id: id },
        query: { currentPage: this.currentPageNumber },
      });
    },
    selectAllRecord() {
      if (this.selectAll) {
        let ids = [];
        this.users.forEach((element) => {
          ids.push(element.id);
        });
        this.multipuleIds = [...new Set(ids)];
        console.log(this.multipuleIds);
      } else {
        this.multipuleIds = [];

        console.log(this.multipuleIds);
      }
    },
    deleteBulk() {
      this.$axios
        .post("delete-user-bulk", { ids: this.multipuleIds })
        .then((response) => {
          this.allUsers({});
          this.triggerSwal(response.data.message, "success");
          this.selectAll = false;
          this.multipuleIds = [];
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>