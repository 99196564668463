<template>
    <div id="chart">
      <apexchart 
        type="line" 
        height="250" 
        :options="chartOptions" 
        :series="series">
      </apexchart>
    </div>
  </template>
  
  <script>
  export default {
    props: ['newStats'],
    name: 'MyChart',
    data() {
      return {
        series: [
          {
            name: 'Active Users',
            data: [],
          },
          {
            name: 'Active Restaurants',
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            type: 'line',
            height: 250,
            toolbar: {
            show: false
          }
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          markers: {
            size: 5,
            colors: ['#ffffff'],
            strokeColors: ['#e74c3c', '#e67e22'],
            strokeWidth: 3,
            hover: {
              size: 7,
            },
          },
          tooltip: {
            shared: true,
            intersect: false,
            marker: {
              show: false,
            },
          },
          colors: ['#e74c3c', '#e67e22'], 
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 0.4,
              opacityTo: 0,
            },
          },
          xaxis: {
            categories: [
              'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
            ],
          },
          grid: {
            strokeDashArray: 4,
          },
          legend: {
            position: 'top',
            horizontalAlign: 'center',
          },
        },
      };
    },
    watch: {
    newStats: {
      immediate: true,
      handler(newStats) {
        if (newStats) {
          
          const usersData = newStats.users?.Monthly_Users
            ? Object.values(newStats.users.Monthly_Users)
            : Array(12).fill(0); 

        
          const restaurantsData = newStats.businesses?.monthly_Restaurants
            ? Object.values(newStats.businesses.monthly_Restaurants)
            : Array(12).fill(0); 

        
          this.series[0].data = usersData; 
          this.series[1].data = restaurantsData; 
        }
      },
    },
  },
  };
  </script>
  
  <style scoped>
  /* You can add custom styles here */
  </style>
  