<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card border border-radius-12">

          <div class="card-body table">
            <div>
              <div class="d-flex flex-wrap justify-content-between align-items-center">
                <PageHeader :title="title" :items="items" class="poppins fw-normal ps-3" />
                <div class="d-flex flex-wrap justify-content-end gap-2">
                  <div class="buttons_wrapper p1 rounded-pill border-orange fit-content"> <router-link
                      to="/business/add"
                      class="
                      rounded-pill btn border-0 px-md-4 px-xl-5 fw-bold text-white btn btn-primary waves-effect waves-light w-md primary-button">
                      Add New
                    </router-link></div>
                  <div class="buttons_wrapper p1 rounded-pill border-dark fit-content">
                    <router-link to="/csvupload" class="
                     btn waves-effect waves-light w-md bg-black  px-md-3 text-nowrap px-xl-5 text-white fw-bold rounded-pill
                                                                ">
                      Bulk Upload
                    </router-link>

                  </div>
                  <template v-if="isShowClearButton">
                    <div class="buttons_wrapper p1 rounded-pill border-orange fit-content">
                      <button @click="clearFilter()" to=""
                        class="
                      rounded-pill btn border-0 px-md-4 px-xl-5 fw-bold text-white btn btn-primary waves-effect waves-light w-md primary-button">
                        Clear
                      </button>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="row justify-content-between">

              <div class="col-xl-12">
                <div
                  class="d-flex align-items-sm-center align-items-start gap-lg-2 gap-1 flex-column flex-sm-row flex-wrap">
                  <searchInput ref="searchInputChild" @getSearched="handleSearch" :searchMessage="this.preSearchValue"
                    :customClass="'w-lg-250'" class="w-sm-100" />
                  <pageSize ref="pageSizeChild" @getPageSize="handlePageSize" :customClass="'w-lg-250'"
                    class="w-sm-100" />
                  <select @change="filtration" v-model="isMember"
                    class="form-select sm-select rounded-pill fs-16 sort-filter px-3 w-lg-250" aria-label="Sort By">
                    <option value="" selected disabled>Sort By</option>
                    <option value="non_ofl_partners"> Non-OFL Partners</option>
                    <option value="ofl_partners">OFL Partners</option>

                  </select>
                  <multiselect class="custom-dropdown-list" ref="category_value" :multiple="true"
                    v-model="category_value" :options="categoryOptions" :searchable="true"
                    @search-change="searchCategory" @fetchMore="loadMoreCategories" @input="onCategoryChange"
                    :className="'w-lg-250 w-sm-100'" :placeHolder="'Filter by Poll Categories'">
                  </multiselect>

                  <template v-if="isFiltered">
                    <multiselect class="custom-dropdown-list" :multiple="true" v-model="newCategoryValue"
                      ref="newCategoryValue" :options="filteredCategoryOptions" :searchable="true"
                      @search-change="searchCategory" @fetchMore="loadMoreCategories" :className="'w-lg-250 w-sm-100'"
                      :placeHolder="'Assign Poll Categories'">
                    </multiselect>

                  </template>
                  <template v-if="isFiltered">
                    <div class=" p1 rounded-pill border-orange fit-content">
                      <button type="button"
                        class="rounded-pill btn border-0 px-md-4 px-xl-5 fw-bold text-white btn btn-primary waves-effect waves-light w-md primary-button"
                        text="Apply" @click="applyCategoryChange">Assign </button>
                    </div>
                  </template>
                </div>
              </div>


              <!-- <div :class="{'buttons_wrapper': true,  'border-orange rounded-pill p1': multipuleIds?.length >= 1}">
                 
                <button v-if="multipuleIds?.length >= 1" @click="deleteBulk" class="
                    btn
                    border-0
                    px-5
                    fw-bold
                    text-white
                    btn btn-primary
                    waves-effect waves-light
                    w-md
                    primary-button
                    rounded-pill
                        ">
                  Delete All
                </button>
              </div> -->


            </div>
            <GeneralTable :isProcessing="processing" :items="items" :fields="fields" @editItem="listingUpdate"
              @deleteItem="deleteRestaurant" :editUrl="'update-list'" @update:selectedRows="handleSelectedRows"
              @no-records-found="onRecordsFount">
              <template #custom-option>

              </template>
            </GeneralTable>
            <Pagination @paginator="allBusiness" :pagination="paginatelinks" :showing="paginate"
              @page-changed="handlePageChange" />


          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import $ from "jquery";
import { slotFlagsText } from "@vue/shared";
import GeneralTable from "../../../../components/MagicTable/GeneralTable.vue";
import searchInput from "../../../../components/general/searchInput.vue";
import pageSize from "../../../../components/general/pageSize.vue";
import Pagination from "../../../../components/general/pagination.vue";
import Multiselect from "../../../../components/general/multiSelect.vue";

export default {
  page: {
    title: "Restaurants",
    meta: [
      {
        name: "add-user",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    GeneralTable,
    searchInput,
    pageSize,
    Pagination,
    Multiselect,

  },
  data() {
    return {
      selectedRestaurantIds: [],
      newCategoryValue: [],
      isFiltered: false,
      categories_id: [],
      categoryOptions: [],
      categorySelected: [],
      page: null,
      categories: null,
      categoryNextPageUrl: null,
      isMember: '',
      currentPageNumber: null,
      fields: [
        { key: 'select', label: 'Select', sortable: false },
        { key: 'id' },
        { key: 'avatar_with_title', label: 'Resturant Name' },
        // { key: 'email',tdClass:''},
        { key: 'mobile', label: 'Phone', },
        { key: 'social_links', label: 'Social Links' },
        { key: 'address', tdClass: 'text-lg-wrap ', label: "Location" },
        { key: 'action', tdClass: '' },
      ],
      mergListingbtn: false,
      selectedBusiness: [],
      selectAll: false,
      processing: false,
      status: null,
      loading: true,
      listing: null,
      title: "Restaurants",
      links: [],
      multipuleIds: [],
      paginate: {},
      paginatelinks: [],
      category_value: [],
      pageSize: 10,
      tempPageNo: null,
      searchValueItem: null,
      preSearchValue: null,
      memberValue: null,
      items: [
        {
          text: "Omaha",
        },
        {
          text: "Business Listing",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.getSearchedValue();
    this.isParamHaveMember();

    this.allCategory();
    let url = null;
    if (this.$route.query.currentPage) {
      url = process.env.VUE_APP_BASEURL + '/all-listing?page=' + this.$route.query.currentPage;
    }
    this.allBusiness({ url });
    this.processing = true;
  },
  created() {
    this.searchCategory = this.debounce(this.searchCategory, 500);
  },
  computed: {
    filteredCategoryOptions() {
      return this.categoryOptions.filter(option => !this.category_value?.includes(option.id));
    },
    isShowClearButton() {
      return this.category_value?.length > 0 || this.searchValue || this.isMember || this.pageSize != 10;
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.items.slice(start, start + this.itemsPerPage);
    }
  },
  methods: {
    onRecordsFount() {

      if(!this.$refs.searchInputChild.searchedItem){
      this.isMember = '';
      const page = this.$refs.pageSizeChild?.pageSize || null;
      this.pageSize = page;
      this.categories_id = [];
      this.$refs.searchInputChild.searchedItem = null;
      let pageValue = 1;

      this.$router.push({
        path: "listing",
        query: { Page: pageValue, pageSize: this.pageSize }
      });
      const baseUrl = "all-listing";
      const pageUrl = `${baseUrl}?page=${pageValue}`;
      this.allBusiness({ url: pageUrl });
      }
      
    },

    isParamHaveMember() {
      if (this.$route.query.is_member) {
        this.isMember = this.$route.query.is_member;
      }
      if (this.$route.query.pageSize) {
        this.pageSize = this.$route.query.pageSize;
        this.$refs.pageSizeChild.pageSize = this.pageSize;
      }
    },
    async getSearchedValue() {
      this.isMemberValue = this.$route.query.is_member;
      const searchQuery = this.$route.query.search;
      if (searchQuery) {
        this.preSearchValue = searchQuery;
        await this.allBusiness();
        this.$router.push({
          path: '/business/listing',
          query: {
            ...this.$route.query,
            search: this.searchValue,
          },
        });
      }
    },
    clearFilter() {
      this.categoryFilterItems();
      this.isMember = '';
      this.categories_id = [];
      this.$refs.pageSizeChild.pageSize = null;
      this.pageSize = 10;
      this.$refs.searchInputChild.searchedItem = null;
      this.allBusiness();
    },
    handleSelectedRows(ids) {
      this.selectedRestaurantIds = ids;
    },
    handlePageChange(pageNumber) {
      if (pageNumber <= 0) {
        this.triggerSwal("Page not found. Please enter a valid page number.", "error");
        return;
      }

      const lastPageUrl = this.paginate?.last_page;
      console.log(pageNumber);
      let lastPage = 1;
      if (lastPageUrl) {
        const match = lastPageUrl.match(/page=(\d+)/);
        if (match && match[1]) {
          lastPage = parseInt(match[1], 10);
        }
      }
      this.$router.push({
        path: "listing",
        query: { currentPage: pageNumber, pageSize: this.pageSize, is_member: this.isMember, search: this.searchValueItem }
      });
      if (pageNumber > lastPage) {
        this.triggerSwal("Page not found. Please enter a valid page number.", "error");
        return;
      }

      const baseUrl = "all-listing";
      const pageUrl = `${baseUrl}?page=${pageNumber}`;
      this.allBusiness({ url: pageUrl });
    },

    categoryFilterItems() {
      if (this.category_value?.length > 0 || this.newCategoryValue?.length > 0) {
        this.newCategoryValue = [];
        this.category_value = [];
        this.isFiltered = false;

        this.$nextTick(() => {
          if (this.$refs.category_value) {
            this.$refs.category_value.value = null;
          }
          if (this.$refs.newCategoryValue) {
            this.$refs.newCategoryValue.value = null;
          }
        });
      }
    },

    applyCategoryChange() {
      if (this.selectedRestaurantIds?.length <= 0) {
        this.triggerSwal("Please select at least one restaurant.", "error");
        return;
      }
      if (this.newCategoryValue?.length <= 0) {
        this.triggerSwal("Please select at least one poll category", "error");
        return;
      }

      const newCategories = this.newCategoryValue.map((category) => category.id);
      const restaurantIds = this.selectedRestaurantIds;
      this.$axios
        .post("update-categories", {
          old_categories: this.categories_id,
          new_categories: newCategories,
          restaurant_ids: restaurantIds,
        })
        .then((response) => {
          this.triggerSwal(response.data, "success");
          this.categoryFilterItems();
          // this.allCategory();
          this.allBusiness();
        })
        .catch((error) => {
          console.error("Error updating categories:", error.response);
        });
    },
    onCategoryChange() {
      this.categories_id = this.category_value?.map((category) => category.id);
      this.allBusiness();
      this.isFiltered = this.categories_id?.length > 0;

    },
    debounce(func, delay) {
      let timer;
      return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(this, args), delay);
      };
    },
    searchCategory: function (query) {
      this.$axios
        .get("search-category", { params: { query } })
        .then((response) => {
          this.categoryOptions = response.data;
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    },
    allCategory(url) {
      let endPoint = url ? url : "categories";
      this.$axios
        .get(endPoint)
        .then((response) => {
          this.categories = response.data.data.data;
          let allCategories = response.data.data.data;
          this.categoryOptions = url ? [...this.categoryOptions, ...allCategories] : allCategories;
          this.categoryNextPageUrl = response.data.data.next_page_url;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    loadMoreCategories() {
      if (this.categoryNextPageUrl) {
        this.allCategory(this.categoryNextPageUrl)
      } else {
        console.log("No more categories to load.");
      }
    },
    filtration(event) {
      if (this.memberValue) {
        this.isMember = this.memberValue;
      } else {
        this.isMember = event.target.value;
      }
      this.allBusiness();

    },
    handleFilter(key) {
      this.hotels = [];
      // this.page = 1;
      this.isMember = key;
      // this.checkIsMemeber(key)
      this.$nextTick(() => {
        this.getData();
      })
    },
    // checkIsMemeber(key){
    //     if(key == 'all') {
    //         return this.isFilterApply = false;
    //     }else {
    //         return this.isFilterApply = true;
    //     }
    // },
    deleteRestaurant(id) {
      let apiUrl = `delete-business-listing/`;
      let confirmationMessage = "Restaurant deleted successfully."
      this.processing = true;
      this.deleteIt(apiUrl, id, confirmationMessage)
        .then(() => {
          this.processing = false;
          this.allBusiness({});
        }).catch(error => {
          console.error('Error deleting job:', error);
          this.processing = false;
        });
      console.log('Received ID from child:', id);
    },
    handleSearch(searchValue) {
      this.tempPageNo = this.currentPageNumber;
      this.searchValueItem = searchValue;
      this.preSearchValue = null;
      this.allBusiness({ searchValue });
    },
    handlePageSize(newPageSize) {
      this.pageSize = newPageSize;
      this.allBusiness({ pageSize: this.pageSize });
    },
    randerData(response) {
      this.currentPageNumber = response.data.data.current_page;
      this.paginate = {
        first_page: response.data.data.first_page_url,
        last_page: response.data.data.last_page_url,
        links: response.data.data.links,
        from: response.data.data.from,
        to: response.data.data.to,
        total: response.data.data.total,
      }
      this.paginatelinks = this.paginate.links.map(item => ({
        url: item.url,
        label: item.label,
        active: item.active,
      }));

      let listing = Array.isArray(response.data.data.data) && response.data.data.data.length > 0
        ? response.data.data.data
        : [];
      this.items = listing.map(list => ({
        id: list.id,
        avatar: list.banner_url,
        name: list.title || "N/A",
        oflMember: list.is_member,
        email: list.email || "N/A",
        mobile: list.mobile || "N/A",
        address: list?.address?.address || "N/A",
        social_links: list.social_media,
      }));
      this.processing = false;
      this.loading = false;
    },
    allBusiness({ url = null, searchValue = null, pageSize = this.pageSize } = {}) {
      this.processing = true;
      let endPoint;
      if (searchValue) {
        endPoint = "all-listing";
      } else {
        endPoint = url || `all-listing?page=${this.currentPageNumber}`;
      }
      endPoint = url ? url : endPoint;
      this.$axios
        .get(endPoint, { params: { search: this.preSearchValue ? this.preSearchValue : this.searchValueItem, page_size: pageSize, is_member: this.isMember, categories_id: this.categories_id } })
        .then((response) => {
          console.log('Business Data:', response.data);
          if (response.data.data.length > 0) {
            this.allBusiness({ url: `all-listing?page=1` })
          }
          setTimeout(() => this.randerData(response), 1500);
        })
        .catch((error) => {
          console.log(error.response);
          this.processing = false;
        });
    },

    getSafe(fn, defaultValue = "N/A") {
      try {
        return fn();
      } catch (error) {
        return defaultValue;
      }
    },
    async listingDeleted(id) {
      try {
        const {
          data: { status },
        } = await this.$axios.delete("delete-business-listing/" + id);
        this.responselistingDelete = status;
      } catch (error) {
        console.log(error);
      }
    },
    deleteListing(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.listingDeleted(id);
          let status = this.responselistingDelete;
          if (status == true) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            this.allBusiness();
          }
        }
      });
    },
    listingUpdate: function (id) {
      console.log(this.pageSize);
      const selectedCategories = this.category_value.map(category => category.id);
      this.$router.push({
        name: "update-list",
        params: { id: id },
        query: { page_size: this.pageSize, search: this.searchValueItem, currentPage: this.tempPageNo ? this.tempPageNo : this.currentPageNumber, is_member: this.isMember, category_value: selectedCategories },
      });
    },
    statusChange(id, status) {
      let data = {
        status: status,
        vendor_listing_id: id,
      };
      this.swalAlert(data, status);
    },

    swalAlert(data, status) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Change it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.apiForStatusChange(data, status);
          Swal.fire("Updated!", "Status has been Changed.", "success");

        }
      });
    },

    apiForStatusChange(data, status) {
      if (status == "rejected") {
        this.rejectListing(data);
      } else {
        this.$axios
          .post("vendor-listing-status", data)
          .then((response) => {
            this.allBusiness();
            this.triggerSwal(response.data.data, "success");
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },
    sponsership(index) {
      let singleRecord = this.listing[index];
      this.$axios
        .post("change-sponsored-ship", {
          id: singleRecord.id,
          sponsorship: singleRecord.sponsorship,
        })
        .then((response) => {
          console.log(response.data);
          this.triggerSwal(response.data.message, "success");
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    rejectListing(data) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Change it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$axios
            .post("vendor-listing-status", data)
            .then((response) => {
              this.allBusiness();
            })
            .catch((error) => {
              console.log(error.response);
            });
          Swal.fire("Updated!", "Status has been Changed.", "success");
          this.allBusiness();
        }
      });
    },
    selectAllRecord() {
      if (this.selectAll) {
        let ids = [];
        this.listing.forEach((element) => {
          ids.push(element.id);
        });
        this.multipuleIds = [...new Set(ids)];
        console.log(this.multipuleIds);
      } else {
        this.multipuleIds = [];

        console.log(this.multipuleIds);
      }
    },
    deleteBulk() {
      this.$axios
        .post("delete-bulk", { ids: this.multipuleIds })
        .then((response) => {
          this.allBusiness();
          this.triggerSwal(response.data.message, "success");
          this.selectAll = false;
          this.multipuleIds = [];
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    approveBulk() {
      this.$axios
        .post("approve-bulk", { ids: this.multipuleIds })
        .then((response) => {
          this.allBusiness();
          this.triggerSwal(response.data.message, "success");
          this.selectAll = false;
          this.multipuleIds = [];
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    mergList(event, businessName) {
      if (event.target.checked) {
        if (this.selectedBusiness?.length == 0) {
          this.selectedBusiness.push(businessName);
          // this.mergListingbtn=true;
        }
        else {
          let status = this.selectedBusiness.find(e => e.title == businessName.title);
          if (status) {
            this.selectedBusiness.push(businessName);
            this.mergListingbtn = true;
          }
          else {
            this.mergListingbtn = false;
          }
        }
      } else {
        this.selectedBusiness.splice(businessName, 1);
        this.selectedBusiness?.length <= 1 ? this.mergListingbtn = false : this.mergListingbtn = true
      }
    },
    listingMerge() {
      this.$axios.post('/merge-listings', this.selectedBusiness).then(response => {
        this.triggerSwal(response.data.message, "success");
        this.mergListingbtn = false;
        this.allBusiness();
      }).catch(error => {
        console.log(error.response);
      })
    }
  },
};
</script>


<style scoped>
.custom-listing .tab {
  padding: 9px !important;
}

.custom-listing.tabs {
  max-width: calc(var(--count)* 346px) !important;
}
</style>