<template>
   
    <div id="chart" class="d-inline-block">
      <apexchart type="donut" width="350" :options="chartOptions" :series="series"></apexchart>
    </div>
  </template>
  
  <script>

  export default {
    props: ['newStats'],
    name: "DonutChart",
    data() {
      return {
        series: [10, 90], 
        chartOptions: {
          labels: ['Premium', 'Free'],
            stroke: {
    show: true,
    width: 0, 
    colors: ['#fff'], 
    lineCap: 'round'
  },
          chart: {
            width: 350,
            type: 'donut',
          },
          plotOptions: {
  pie: {
    startAngle: -90,
    endAngle: 270,
    donut: {
      labels: {
        show: true,
        total: {
          showAlways: true,
          show: true,
          label: 'Total',
          fontSize: '11px',
          fontWeight: ''
        }
      }
    }
  }
},
          dataLabels: {
            enabled: false,
            
          },
          fill: {
            type: 'gradient',
          },
          legend: {
            position: 'left',
          offsetY: 70,
          offsetX: -20,
            formatter: function(val, opts) {
              return val + " - " + opts.w.globals.series[opts.seriesIndex];
            },
          },
          colors: ['#f8b4a5', '#EC421C', '#B0BEC5'], // Orange, Light Orange, and Gray
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 350,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        },
      };
    },
    watch: {
    newStats: {
      immediate: true,
      handler(newStats) {
        const countUsers = newStats.subscriptions;
        this.series = Object.values(countUsers);
      },
    },
  },
  };
  </script>
  

  